import mongoose, { Document, Schema } from 'mongoose';
import { ActivityTypes } from '@packages/mongodb';
export interface ClientActivity {
  name: string;
}

// Moves - applies for memory pairs and puzzle picture
// Errors - applies to spot the difference
// None - applies to all the rest of the activities (activities which we do NOT measure performance yet)
export const PERFORMANCE_TYPE = {
  moves: 'Moves',
  errors: 'Errors',
  none: 'None',
};

export const ACTIVITY_TYPE_PERFORMANCE = {
  [ActivityTypes.MemoryPairs]: PERFORMANCE_TYPE.moves,
  [ActivityTypes.PicturePuzzle]: PERFORMANCE_TYPE.moves,
  [ActivityTypes.SpotDifferences]: PERFORMANCE_TYPE.errors,
};

export enum CLIENT_DASHBOARD_TYPE {
  SYNC_ACTIVITY = 1,
  SYNC_SESSION = 2,
  ASYNC_ACTIVITY = 3,
  ASYNC_SESSION = 4,
}

export interface MongooseClientActivity extends ClientActivity, Document {}

const ClientActivitySchema = new Schema(
  {
    client: { type: Schema.Types.ObjectId, ref: 'Client', default: null },
    user: { type: Schema.Types.ObjectId, ref: 'User' },
    activity: { type: Schema.Types.ObjectId, ref: 'Activity' },
    homePracticeLinkId: { type: String, default: null },
    browserId: { type: String, default: null },
    startedAt: { type: Date, required: true },
    completedAt: { type: Date, default: null },
    summarySlideStartedAt: { type: Date, default: null },
    summarySlideCompletedAt: { type: Date, default: null },
    duration: { type: Number, default: 0 },
    isCompleted: { type: Boolean, default: false },
    isValidRecord: { type: Boolean, default: true },
    // isValidRecord : field used to identify if record is collected will all needed info or not which can affect dashboard statics will be false in inaccurate colleted data. and apis should filter out such from static consideration
    userInteraction: { type: Boolean, default: false },
    totalMoves: { type: Number, default: null },
    totalErrors: { type: Number, default: null },
    performanceType: {
      type: String,
      enum: Object.values(PERFORMANCE_TYPE),
      default: null,
    },
    tasksStatistics: [
      {
        taskIndex: { type: Number, required: true },
        startedAt: { type: Date, required: true },
        completedAt: { type: Date, default: null },
        duration: { type: Number, default: 0 },
        isCompleted: { type: Boolean, default: false },
        taskErrors: { type: Number, default: null },
        taskMoves: { type: Number, default: null },
      },
    ],
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const ClientActivity = mongoose.model<MongooseClientActivity>(
  'Client-Activity',
  ClientActivitySchema
) as any;
