import axios from 'axios';
import { useStorage, StorageTypes } from '@/services/storage';
import to from 'await-to-js';
import { environment } from '@/env';

const defaultOptions = {
  store: null,
  router: null,
  gtm: null,
  authRedirect: { path: '/login' },
  forbiddenRedirect: { path: '/403' },
  notFoundRedirect: { path: '/404' },
};

let options = { ...defaultOptions };

const { get, save, destroy } = useStorage();

const loadFromStorage = () => {
  const [user, token] = [get(StorageTypes.USER), get(StorageTypes.TOKEN)];
  return user && token && { token, user };
};

const updateAuth = ({ token, user }) => {
  console.log({ token, user });

  save(StorageTypes.TOKEN, token);
  save(StorageTypes.USER, user);
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  const { store, gtm } = options;

  store?.dispatch({ type: 'auth/updateAuth', payload: { token, user } });

  gtm?.trackEvent({
    event: 'login',
    userId: user.id,
  });
};

export const logout = () => {
  [StorageTypes.TOKEN, StorageTypes.USER].forEach((i) => destroy(i));

  delete axios.defaults.headers.common.Authorization;

  const { store, gtm, router, authRedirect } = options;

  store?.dispatch({ type: 'auth/logout' });

  if (router?.currentRoute?.meta?.requiresAuth) {
    save(StorageTypes.REDIRECT_LOGIN, router?.currentRoute?.path);
    router.push(authRedirect);
  }
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response: any) => {
    if (response?.config?.ignoreInterceptors) {
      return response;
    }

    const token = response.headers['x-access-token'];
    if (token) {
      updateAuth({ token, user: response.data.user });
    }
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (originalRequest?.ignoreInterceptors) {
      return Promise.reject(error);
    }

    const otpError = error.response?.data?.error === 'UNAUTHORIZEDOTP';

    if (otpError && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const otp = error.response.headers['x-otp']; // window.localStorage.getItem('refreshToken');
      return new Promise(function (resolve, reject) {
        axios
          .post('/api/auth/authorize', {}, { headers: { Authorization: `Bearer ${otp}` } })
          .then((response) => {
            const token = response.headers['x-access-token'];

            updateAuth({ token, user: response.data.user });

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            error.config.headers.Authorization = `Bearer ${token}`;

            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            console.log({ err });

            processQueue(err, null);

            if (401 === err?.response?.status && err?.response?.data?.error === 'UNAUTHORIZED') {
              setTimeout(() => logout(), 100);
            }

            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    if (401 === error.response.status && error?.response?.data?.error === 'UNAUTHORIZED') {
      setTimeout(() => logout(), 100);
      return;
    }

    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (response: any) => {
//     if (response?.config?.ignoreInterceptors) {
//       return response;
//     }

//     const token = response.headers['x-access-token'];
//     if (token) {
//       updateAuth({ token, user: response.data.user });
//     }
//     return response;
//   },
//   async (error) => {
//     if (error?.config?.ignoreInterceptors) {
//       return Promise.reject(error);
//     }

//     const status = error?.response?.status;
//     const otpError = error?.response?.data?.error === 'UNAUTHORIZEDOTP';
//     const accessError = error?.response?.data?.error === 'UNAUTHORIZED';

//     if (401 === status && otpError) {

//       const opt = error.response.headers['x-otp'];

//       const [err, res] = await to(
//         axios({
//           url: `/api/auth/authorize`,
//           method: 'post',
//           headers: { Authorization: `Bearer ${opt}` },
//           ignoreInterceptors: true,
//         } as any)
//       );

//       if (err) {
//         setTimeout(() => logout(), 100);
//         return Promise.reject(error);
//       }

//       if (!err) {
//         const token = res.headers['x-access-token'];

//         if (token) {
//           updateAuth({ token, user: res.data.user });

//           error.config.headers.Authorization = `Bearer ${token}`;

//           return axios.request(error.config);
//         }
//       }
//     }

//     if (401 === status && accessError) {
//       setTimeout(() => logout(), 100);
//     }

//     return Promise.reject(error);
//   }
// );

export const createAuth = (authOptions) => {
  options = { ...defaultOptions, ...authOptions };

  const payload = loadFromStorage();
  console.log({ payload });
  if (payload) {
    updateAuth(payload);
  }

  const { router, store, authRedirect } = options;

  router?.beforeEach((to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      return next();
    }

    const token = store.get('auth/token');
    if (token) {
      return next();
    }

    const redirectTo = to.fullPath;
    save(StorageTypes.REDIRECT_LOGIN, redirectTo);

    return next(authRedirect);
  });
};
