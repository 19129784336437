import { Model } from '@vuex-orm/core';

export class Role extends Model {
  public static entity = 'roles';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      enabled: this.attr(null),
    };
  }
}
