// --------------------------------------------------
// LOCAL STORAGE CODE:
// --------------------------------------------------
// import SecureLS from 'secure-ls';

// const ls = new SecureLS({ encodingType: 'aes' });

// export enum StorageTypes {
//   USER = 'cu',
//   TOKEN = 'ct',
//   KEEP_LOGIN = 'ckl',
//   REDIRECT_LOGIN = 'crl',
//   SHOW_VERIFY_USER_MESSAGE = 'svum',
// }

// export const useStorage = () => {
//   const destroy = (key: string) => {
//     ls.remove(key);
//   };

//   const get = (key: StorageTypes) => {
//     try {
//       const item = ls.get(key);
//       return item && JSON.parse(item);
//     } catch (e) {
//       destroy(key);
//     }
//   };

//   const save = (key: StorageTypes, item: any) => {
//     ls.set(key, JSON.stringify(item));
//   };

//   return { get, save, destroy };
// };

// --------------------------------------------------
// SESSION STORAGE CODE:
// --------------------------------------------------
import { EncryptStorage } from 'encrypt-storage';

const ss = new EncryptStorage('secret-key-value', { storageType: 'sessionStorage' });

export enum StorageTypes {
  USER = 'cu',
  TOKEN = 'ct',
  KEEP_LOGIN = 'ckl',
  REDIRECT_LOGIN = 'crl',
  SHOW_VERIFY_USER_MESSAGE = 'svum',
}

export const useStorage = () => {
  const destroy = (key: string) => {
    console.log('destroy : ', key);
    ss.removeItem(key);
    return;
  };

  const get = (key: StorageTypes) => {
    try {
      const item = JSON.stringify(ss.getItem(key));
      return item && JSON.parse(item);
    } catch (e) {
      console.log('destroy in catch : ', e);
      destroy(key);
    }
  };

  const save = (key: StorageTypes, item: any) => {
    ss.setItem(key, JSON.stringify(item));
  };

  return { get, save, destroy };
};
