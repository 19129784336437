import { Model } from '@vuex-orm/core';
import { Domain } from './domain';

export class ActivityType extends Model {
  public static entity = 'activity-types';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      label: this.attr(null),
      domain: this.belongsTo(Domain, 'domain_id'),
      domain_id: this.attr(''),
      sourceActivityType_id: this.attr(''),
      sourceActivityType: this.belongsTo(ActivityType, 'sourceActivityType_id'),
      hasTextToSpeechAudio: this.attr(false),
      hiddenInLanguages: this.attr([]),
    };
  }
}
