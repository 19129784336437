import mongoose, { Document, Schema } from 'mongoose';
// import { SURVEY_ENUM } from '@packages/mongodb';
import { SURVEY_ENUM } from './survey';

export interface SurveyResults {
  name: string;
}

export interface MongooseSurveyResults extends SurveyResults, Document {}

const SurveyResultsSchema = new Schema(
  {
    surveyId: { type: Schema.Types.ObjectId, ref: 'Survey' },
    surveyHeader: { type: String },
    surveyName: { type: String },
    userId: { type: Schema.Types.ObjectId, ref: 'User' },
    userEmail: { type: String },
    questionId: { type: Schema.Types.ObjectId },
    questionText: { type: String },
    questionType: { type: String, enum: SURVEY_ENUM.questionType },
    questionAnswerValue: { type: String },
    questionAnswerAdditionalText: { type: String },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const SurveyResults = mongoose.model<MongooseSurveyResults>(
  'Survey-Results',
  SurveyResultsSchema
) as any;
