import mongoose, { Document, Schema } from 'mongoose';

export interface ActivityClientFeedback {
  clientId: any;
}

export enum FEEDBACK_ENUM {
  'VeryEasy' = 1,
  'Easy' = 2,
  'Natural' = 3,
  'Hard' = 4,
  'VeryHard' = 5,
}

export interface MongooseActivityClientFeedback
  extends ActivityClientFeedback,
    Document {}

const ActivityClientFeedbackSchema = new Schema(
  {
    user: { type: Schema.Types.ObjectId, ref: 'User' },
    client: { type: Schema.Types.ObjectId, ref: 'Client' },
    feedbackGivenAt: { type: Date, default: null },
    activity: { type: Schema.Types.ObjectId, ref: 'Activity' },
    activityType: { type: Schema.Types.ObjectId, ref: 'Activity-Type' },
    clientActivity: {
      type: Schema.Types.ObjectId,
      ref: 'Client-Activity',
      default: null,
    },
    feedback: {
      type: Number,
      default: null,
      enum: Object.values(FEEDBACK_ENUM),
    },
    skip: { type: Boolean, default: false },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const ActivityClientFeedback = mongoose.model<
  MongooseActivityClientFeedback
>('Activity-Client-Feedback', ActivityClientFeedbackSchema) as any;
