import { make } from 'vuex-pathify';

const state = {
  user: null,
  token: null,
  error: null,

  // verifyRequired: false,
  // notifyOpen: false,
};

const mutations = {
  ...make.mutations(state),
  loginSuccess(state, { user, token }) {
    state.user = user;
    state.token = token;

    // state.verifyRequired = !user.verified;
    // state.notifyOpen = !user.verified;
  },

  updateAuth(state, { user, token }) {
    state.user = user;
    state.token = token;

    // state.verifyRequired = !user.verified;
    // state.notifyOpen = !user.verified;
  },

  logout(state) {
    state.user = null;
    state.token = null;

    // state.verifyRequired = null;
    // state.notifyOpen = false;
  },
};

const actions = {
  login() {
    //
  },

  loginSuccess({ state, commit }, { payload }) {
    commit('loginSuccess', { user: payload.user, token: payload.token });
  },

  updateAuth({ state, commit }, { payload }) {
    commit('updateAuth', { user: payload.user, token: payload.token });
  },

  logout({ state, commit }) {
    commit('logout');
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
