import mongoose, { Document, Schema } from 'mongoose';

export const PURCHASE_ENUM = {
  chargingCycle: ['monthly', 'yearly', 'one-time'],
};

export interface UserProfile {
  user: any;
  purchases: any;
}

export interface MongoosePurchase extends UserProfile, Document {}

const PurchaseSchema = new Schema({
  user: { type: mongoose.Schema.Types.ObjectId, ref: 'User' },
  purchases: [
    {
      plan: { type: mongoose.Schema.Types.Mixed }, // { type: mongoose.Schema.Types.ObjectId, ref: 'Plan' },
      status: { type: String },
      charged: { type: String },
      currency: { type: String },
      cycleChargeAmount: { type: Number },
      paypalPlanId: { type: String },
      initialNextBillingTime: { type: Date },
      purchaseDate: { type: Date },
      cancellationDate: { type: Date },
      cancellationSource: { type: String },
      autoRenew: { type: Boolean, default: true },
      paypalPayerId: { type: String },
      paypalSubscriptionId: { type: String },
      subscriptionStartDate: { type: Date },
      subscriptionEndDate: { type: Date },
      planId: { type: Schema.Types.ObjectId, ref: 'Plan' },
      coupon: { type: String },
      licenses: { type: String },
      _raw: { type: String },
      createdAt: { type: mongoose.Schema.Types.Date },
    },
  ],
});

export const Purchase = mongoose.model<MongoosePurchase>(
  'Purchase',
  PurchaseSchema
) as any;
