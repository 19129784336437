import { Role } from '../store/entities/role';
import { usePromise } from 'vue-composable';
import { loadEntity } from '../services/api';
import dasherize from 'dasherize';
import * as fromEntities from '@/store/entities';

export const getAllRoles = async () => {
  if (Role.all().length === 0) {
    const entity = 'Role';
    const api = usePromise(() => loadEntity(dasherize(entity).toLowerCase()), { lazy: true });
    await api.exec();
    if (!api.error.value) {
      await fromEntities[entity].insertOrUpdate({ data: api.result.value.data });
    }
  }
};
