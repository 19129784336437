import { Model } from '@vuex-orm/core';
import { USER_SELF_PRACTICE_LIMIT, USER_PRINTS_LIMIT } from '@packages/mongodb';

export class User extends Model {
  public static entity = 'user';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      email: this.attr(''),
      verified: this.attr(''),
      licenses: this.attr(''),
      role: this.attr(null),
      roles: this.attr([]),
      // profile: this.attr({}),
      team: this.attr(null),
      password: this.attr(null),
      preferences: this.attr({
        printsLimit: USER_PRINTS_LIMIT.userPrintLimit,
        printsLeftThisMonth: USER_PRINTS_LIMIT.userPrintsLeftThisMonth,
      }),
      organization: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      organizationRole: this.attr(null),
      selfPractice: this.attr({
        selfPracticeShareLimit: USER_SELF_PRACTICE_LIMIT.userSelfPracticeLimit,
        shareLeftThisMonth: USER_SELF_PRACTICE_LIMIT.userSelfPracticeLimit,
      }),
      isPaying: this.attr(false),

      active: this.attr(true),
      externalPaymentPlan: this.attr(false),
      plan: this.attr(null),
      purchase: this.attr(null),
      facebook: this.attr(null),
      google: this.attr(null),
      linkedin: this.attr(null),
      surveyNotificationsStatistics: this.attr(null),
      comments: this.attr(''),
      purchaseComments: this.attr(''),
    };
  }
}
