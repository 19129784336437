import kebabCase from 'lodash/kebabCase';

interface Meta {
  requiresAuth?: boolean;
  hasToolbar?: boolean;
  hasDrawer?: boolean;
  hasFooter?: boolean;
  hasSystemNotification?: boolean;
}

export function getLanguageCookie() {
  return typeof document === 'undefined' ? undefined : 'en';
  // : new Map(
  //     document.cookie.split('; ').map(c => c.split('='))
  // ).get('currentLanguage')
}

export function layout(path, name, children) {
  const folder = kebabCase(name);

  return {
    path,
    component: () => import(/* webpackChunkName: "layout-[request]" */ `@/layouts/${folder}/index`),
    props: true,
    children,
  };
}

export function root(children) {
  return [
    layout(
      '/', // '/:lang([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})',
      'root',
      children
    ),
    // redirectLang()
  ];
}

export function redirect(redirect) {
  return { path: '*', redirect };
}

// export function redirectLang(path = '') {
//   // language regex:
//   // /^[a-z]{2,3}(?:-[a-zA-Z]{4})?(?:-[A-Z]{2,3})?$/
//   // /^[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3}$/
//   const languageRegex = /^\/([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})(?:\/.*)?$/;
//   const fallbackLocale = languages.find((lang) => lang.fallback === true).locale;

//   return redirect((to) => {
//     let lang = `/${getLanguageCookie() || fallbackLocale}`;

//     if (!languageRegex.test(lang)) {
//       lang = `/${fallbackLocale}`;
//     }

//     return `${lang}${path || to.path}`;
//   });
// }

export function route(path, name, meta?: Meta, children = []) {
  return {
    component: () => import(/* webpackChunkName: "page-[request]" */ `@/pages/${name}/index/index.vue`),
    name,
    path,
    meta,
    children,
  };
}

// export function redirectTo(path, url) {
//   // language regex:
//   // /^[a-z]{2,3}(?:-[a-zA-Z]{4})?(?:-[A-Z]{2,3})?$/
//   // /^[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3}$/
//   const languageRegex = /^\/([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})(?:\/.*)?$/;
//   const fallbackLocale = languages.find((lang) => lang.fallback === true).locale;

//   return {
//     path,
//     redirect: (to) => {
//       let lang = `/${getLanguageCookie() || fallbackLocale}`;

//       if (!languageRegex.test(lang)) {
//         lang = `/${fallbackLocale}`;
//       }
//       return `${lang}/${url}`;
//     },
//   };
// }

// export function redirectNotFound() {
//   // language regex:
//   // /^[a-z]{2,3}(?:-[a-zA-Z]{4})?(?:-[A-Z]{2,3})?$/
//   // /^[a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3}$/
//   const languageRegex = /^\/([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})(?:\/.*)?$/;
//   const fallbackLocale = languages.find((lang) => lang.fallback === true).locale;

//   return {
//     path: '*',
//     redirect: (to) => {
//       let lang = `/${getLanguageCookie() || fallbackLocale}`;

//       if (!languageRegex.test(lang)) {
//         lang = `/${fallbackLocale}`;
//       }

//       const x = `${lang}/404`;
//       return x;
//     },
//   };
// }
