import mongoose, { Document, Schema } from 'mongoose';


export interface ClientSession {
  clientId: any;
}

export interface MongooseClientSession extends ClientSession, Document { }

const ClientSessionSchema = new Schema(
  {
    clientId: { type: Schema.Types.ObjectId, ref: 'Client' },
    userId: { type: Schema.Types.ObjectId, ref: 'User', index: true },
    sessionLength: { type: Number },
    validSession: { type: Boolean, default: false },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const ClientSession = mongoose.model<MongooseClientSession>(
  'Client-Session',
  ClientSessionSchema
) as any;
