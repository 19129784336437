import mongoose, { Document, Schema } from 'mongoose';

export interface Print {
  imageName: String;
  printCount: Number;
}

export interface MongoosePrint extends Print, Document {}

const printSchema = new Schema({
  imageName: { type: String, unique: true },
  printCount: { type: Number, default: 0 },
});

export const Print = mongoose.model<MongoosePrint>('Print', printSchema) as any;
