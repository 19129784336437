import mongoose, { Document, Schema } from 'mongoose';

export const ENUM = {
  Enrichment: ['Plain', 'Facts'],
  Cognition: [
    'WhatsInThePicture',
    'CommonGround',
    'SoundOfLifePhoto',
    'MemoryCards',
    'Commonality',
    'HowToMake',
  ],
  Speech: ['ArticulationWord', 'ArticulationPhrase', 'ArticulationSentense'],
  Language: ['Meaning', 'WHQuestions', 'GoodStory'],
};

export enum DOMAIN_LIST {
  SOCIAL_EMOTIONAL = 'Social-Emotional',
  COGNITION = 'Cognition',
  SPEECH = 'Speech',
  LANGUAGE = 'Language',
  STUDIO = 'Studio',
}

export const HiddenDomains = [DOMAIN_LIST.STUDIO];

export interface Domain {
  name: string;
}

export interface MongooseDomain extends Domain, Document {}

const DomainSchema = new Schema({
  name: { type: String, unique: true, index: true },
  sortOrder: { type: Number },
});

export const Domain = mongoose.model<MongooseDomain>(
  'Activity-Domain',
  DomainSchema
) as any;
