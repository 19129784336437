import mongoose, { Document, Schema } from 'mongoose';

export interface Website {
  website: string;
  isPublic: Boolean;
  customizedInterface: Boolean;
  inheritInterfaceFrom: Schema.Types.ObjectId;
  phonologyInheritance: Schema.Types.ObjectId;
  language: Schema.Types.ObjectId;
}

export enum AVAILABLE_SIZE {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extralarge = 'extralarge',
  custom = 'custom',
}

export interface MongooseWebsite extends Website, Document {}

const WebsiteSchema = new Schema(
  {
    website: { type: String, default: null },
    isPublic: { type: Boolean, default: false },
    customizedInterface: { type: Boolean, default: true },
    inheritInterfaceFrom: { type: Schema.Types.ObjectId, default: null },
    phonologyInheritance: { type: Schema.Types.ObjectId, default: null },
    language: { type: Schema.Types.ObjectId, ref: 'Language', default: null },
    isPrimaryWebsite: { type: Boolean, default: false },
    homePageTopStrip: {
      backgroundColor: { type: String, default: '#363636' },
      message: {
        shown: { type: Boolean, default: false },
        fontColor: { type: String, default: '#ffffff' },
        size: {
          type: String,
          default: AVAILABLE_SIZE.large,
          enum: Object.keys(AVAILABLE_SIZE),
        },
        message: { type: String, default: '' },
      },
      logo: {
        shown: { type: Boolean, default: false },
        size: {
          type: String,
          default: AVAILABLE_SIZE.large,
          enum: Object.keys(AVAILABLE_SIZE),
        },
        height: {
          type: Number,
        },
      },
    },
    weekStartsOnSunday: { type: Boolean, default: false },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const Website = mongoose.model<MongooseWebsite>(
  'Website',
  WebsiteSchema
) as any;
