import mongoose, { Document, Schema } from 'mongoose';
import { ACTIVITY_GENERATION_TYPE_REFERENCE } from './dynamic-reference-enums/dynamic-model-reference';

export interface Board {
  name: string;
  user: any;
  items: any[];
}
export const BOARD_TYPE = {
  clientBoard: 1,
  myBoard: 2,
  organizationBoard: 3,
};

export interface MongooseBoard extends Board, Document {}

export const AVAILABLE_BOARD_PIN_TYPES = {
  pin: 'pin',
  unPin: 'unpin',
};

const BoardSchema = new Schema(
  {
    name: { type: String },
    user: { type: mongoose.Schema.Types.ObjectId, ref: 'User' },
    client: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'Client',
      default: null,
    },
    organization: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'Organization',
      default: null,
    },
    items: [
      {
        modelId: {
          type: mongoose.Schema.Types.ObjectId,
          required: true,
          refPath: 'items.onModel',
        },
        onModel: {
          type: String,
          required: true,
          enum: Object.values(ACTIVITY_GENERATION_TYPE_REFERENCE),
        },
      },
    ],
    boardType: {
      type: Number,
      enum: Object.values(BOARD_TYPE),
      default: BOARD_TYPE.myBoard,
    },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const Board = mongoose.model<MongooseBoard>('Board', BoardSchema) as any;
