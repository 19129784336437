<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref, getCurrentInstance, onMounted } from '@vue/composition-api';
import { useState } from '@u3u/vue-hooks';

import { refreshAfter48Hours, removeServiceWorker } from './services/app';

export default {
  setup() {
    const { version } = useState('app', ['version']);
    const { user } = useState('auth', ['user']);

    console.log(`--- ${version.value} ---`);

    return {};
  },
};
</script>

<style lang="scss" scoped></style>
