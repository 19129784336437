import mongoose, { Document, Schema } from 'mongoose';
import { BOARD_TYPE } from '@packages/mongodb';

export interface List {
  description: string;
  type: Number;
  list: any[];
}
export interface MongooseList extends List, Document {}

const ListSchema = new Schema({
  organization: {
    type: Schema.Types.ObjectId,
    ref: 'Organization',
    default: null,
  },
  type: {
    type: Number,
    enum: BOARD_TYPE,
    default: BOARD_TYPE.myBoard,
  },
  description: { type: String, default: null },
  list: { type: [String], default: [] },
  language: { type: Schema.Types.ObjectId, ref: 'Language', required: true },
});

export const Lists = mongoose.model<MongooseList>('Lists', ListSchema) as any;
