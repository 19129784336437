import { Model } from '@vuex-orm/core';

export class LanguagesAlongWithWebsite extends Model {
  public static entity = 'language';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      rtl: this.attr(false),
      languageCode: this.attr(''),
      fontFamily: this.attr(''),
      fallback: this.attr(false),
      website: this.attr([]),
      checked: this.attr(false),
      requiresManualTextToSpeech: this.attr(false),
      hasGender: this.attr(false),
      hasSingularOrPlural: this.attr(false),
      hasPunctuation: this.attr(false),
    };
  }
}
