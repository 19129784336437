import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import dasherize from 'dasherize';

import VueAspectRatio from 'vue-aspect-ratio';

const requireComponent = require.context('./', true, /\.vue$/, 'lazy');

requireComponent.keys().forEach((fileName) => {
  const componentName = dasherize(
    upperFirst(
      camelCase(
        fileName
          .replace(/^\.\//, '')
          .replace(/\.\w+$/, '')
          .replace(/index$/, '')
      )
    )
  );

  Vue.component(componentName, () => requireComponent(fileName));
});

Vue.component('vue-aspect-ratio', VueAspectRatio);

// Vue.component('vue-markdown', () => import('./vue-markdown'));
