export default {
  props: {
    x: {
      type: String,
      default: 'right',
    },
    y: {
      type: String,
      default: 'bottom',
    },
    color: {
      type: String,
      default: 'info',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    classes: {
      type: [String, Object, Array],
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    timeout: {
      type: Number,
      default: 3000,
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    closeText: {
      type: String,
      default: '',
    },
    closeIcon: {
      type: String,
      default: 'close',
    },
    closeColor: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    active: false,
  }),

  mounted() {
    this.$nextTick(() => this.show());
  },

  watch: {
    active: function (isActive, wasActive) {
      this.$emit('statusChange', isActive, wasActive);
    },
  },

  methods: {
    show() {
      this.active = true;
    },

    close() {
      this.active = false;
    },

    dismiss() {
      if (this.dismissable) {
        this.close();
      }
    },
  },
};
