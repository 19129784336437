// Utilities
import { make } from 'vuex-pathify';
import { environment } from '../../env';

export enum LoadStatus {
  UNSET = 0,
  LOADED,
  LOADING,
}

// const [version, env] = ((document.getElementById('app-env') as HTMLMetaElement)?.content || '0.0.0-dev, dev').split(', ');

// export const global = {
//   url: window.location.href,
//   userAgent: window.navigator.userAgent,
//   mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
//   version,
//   env,
//   pwa: new URLSearchParams(window.location.search).get('utm_source') === 'PWA',
// };

const state = {
  currentVersion: null,
  drawer: null,
  isLoading: false,
  releases: [],
  metadata: LoadStatus.UNSET,

  hasToolbar: true,
  hasDrawer: true,
  hasFooter: true,
  hasSystemNotification: true,

  userAgent: window.navigator.userAgent,

  mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent),
  pwa:
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as any).standalone ||
    document.referrer.includes('android-app://'), // new URLSearchParams(window.location.search).get('utm_source') === 'PWA',

  version: environment.version,
  hasHighQuality: false,
  env: environment.env,

  isShowPrivateWebsite: true,
};

const mutations = {
  ...make.mutations(state),

  setPrivateWebsiteAsFalse(state) {
    state.isShowPrivateWebsite = false;
  },
  setPrivateWebsiteAsTrue(state) {
    state.isShowPrivateWebsite = true;
  },
};

const actions = {
  setPrivateWebsiteAsFalseAction({ state, commit }) {
    commit('setPrivateWebsiteAsFalse', { isShowPrivateWebsite: false });
  },
  setPrivateWebsiteAsTrueAction({ state, commit }) {
    commit('setPrivateWebsiteAsTrue', { isShowPrivateWebsite: true });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

// // Utilities
// import { make } from 'vuex-pathify';

// export enum LoadStatus {
//   UNSET = 0,
//   LOADED,
//   LOADING,
// }

// const state = {
//   currentVersion: null,
//   drawer: true,
//   isLoading: false,
//   releases: [],
//   metadata: LoadStatus.UNSET,

//   hasToolbar: true,
//   hasDrawer: true,
//   hasFooter: true,
//   hasSystemNotification: true,

//   mobile: false,
//   pwa: false,

//   version: '0.0.0-NO_VERSION',

//   appenv: 'prod',
// };

// const mutations = make.mutations(state);

// const actions = {};

// const getters = {};

// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions,
//   getters,
// };
