console.log('in main');

import Vue from 'vue';
import './sass/style.scss';

import './env';
import './components';

import { createRouter } from './router';
import { createStore } from './store';
import { sync } from 'vuex-router-sync';
import './plugins/composition-api';
import './plugins/handle-error';
import './plugins/vue-hammer';
import './plugins/vue-popover';
import './plugins/element-ui';

import { createVuetify } from './plugins/vuetify';
import { nprogress } from './plugins/vue-nprogress';

import App from './App.vue';
import { createAuth } from './plugins/auth';
import { getAllRoles } from './utils/perFetch';
import { updateUserInState } from '@/services/api';
import VueMeta from 'vue-meta';
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import { registerLicense } from '@syncfusion/ej2-base';

(async () => {
  Vue.use(GridPlugin);
  registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFac1lJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RjUH9bcXVRR2RUVEQ=');
  Vue.use(VueMeta, {
    keyName: 'head',
    // optional pluginOptions
    //refreshOnceOnNavigation: true
  });
  const vuetify = createVuetify();
  const router = createRouter();
  const store = createStore();

  createAuth({ store, router });

  sync(store, router);

  const app = new Vue({
    vuetify,
    router,
    store,
    // i18n,
    nprogress,
    render: (h) => h(App),
  });

  await Promise.all([getAllRoles(), updateUserInState()]);
  app.$mount('#app');
})();
