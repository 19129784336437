import mongoose, { Document, Schema } from 'mongoose';

export interface HeartBeatInterface {
  sessionId: string;
}

export enum HeartBeatTimings {
  IDLE_TIME_BEFORE_SESSION_RESET = 300000, //5 minutes
  TIME_BETWEEN_EACH_HEARTBEAT = 20000, // 20 seconds
  IDLE_TIME_BEFORE_STOP_SENDING_HEARTBEAT = 60000, //1 minute
  INITIAL_NEW_SESSION_END_TIME = 1, // 10 seconds
  INITIAL_NEW_SESSION_DURATION = 100, // 100 ms
}

export interface MongooseHeartBeat extends HeartBeatInterface, Document {}

const HeartBeatSchema = new Schema(
  {
    sessionId: { type: String, required: true, unique: true },
    sessionStart: { type: Date, default: null },
    sessionEnd: { type: Date, default: null },
    duration: { type: Number, default: 0 },
    user: {
      type: Schema.Types.ObjectId,
      ref: 'User',
    },
    userName: {
      type: Schema.Types.String,
      required: false,
      default: null,
    },
    userEmail: {
      type: Schema.Types.String,
      required: false,
      default: null,
    },
    client: {
      type: Schema.Types.ObjectId,
      ref: 'Client',
      default: null,
    },
    clientName: { type: Schema.Types.String, required: false, default: null },
    clientEmail: { type: Schema.Types.String, required: false, default: null },
    selfPracticeLinkId: { type: String, required: false, default: null },
    browserId: { type: String, default: null },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

HeartBeatSchema.virtual('homePractice', {
  ref: 'Home-Practice',
  localField: 'selfPracticeLinkId',
  foreignField: 'linkId',
});

export const HeartBeat = mongoose.model<MongooseHeartBeat>(
  'HeartBeat',
  HeartBeatSchema
) as any;
