import { findActivityOne, findArticulationOne } from '@/services/api';
import { Activity, LanguagesAlongWithWebsite, Role } from '@/store/entities';
import { hasRoleInUserOrOrg, Roles } from '@packages/roles';
import { useState } from '@u3u/vue-hooks';
import { document, window } from 'global';
import { usePromise } from 'vue-composable';
import dasherize from 'dasherize';
import {
  ACTIVITY_ENUM,
  ACTIVITY_PLANNING_COLUMN_TYPE,
  ALLOWED_LANGUAGES,
  ActivityTypes,
  DROPPER_TYPE,
  STORY_SEQUENCE_ACTIVITY_CONFIG,
} from '@packages/mongodb';
import moment from 'moment';
import { environment } from '@/env';

const activitySlidesPreLevels = {
  MemoryPairs: {
    1: 4,
    2: 4,
    3: 8,
    4: 8,
    5: 10,
    6: 10,
    7: 15,
    8: 15,
    9: 18,
    10: 18,
  },
};

const slidesToCheckActivities = ['MemoryPairs'];

export const isSlideToCheckActivity = (activityType) => {
  return slidesToCheckActivities.includes(activityType);
};

export const isSlideLengthNotBaseOnLevel = (activityType, slideLength, level) => {
  if (activitySlidesPreLevels[activityType][level] !== slideLength) {
    return true;
  }
  return false;
};

export async function waitForReadystate() {
  console.log('in waitfor function');
  if (typeof document !== 'undefined' && document.readyState !== 'complete') {
    await new Promise<void>((resolve) => {
      const cb = () => {
        console.log('in cb');
        window.requestAnimationFrame(() => {
          console.log('in request anim ');
          resolve();
        });
        window.removeEventListener('load', cb);
      };
      console.log('wait for ready state');
      window.addEventListener('load', cb);
    });
  }
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitForNextFrame() {
  return new Promise<void>((resolve) => {
    window.requestAnimationFrame(() => {
      resolve();
    });
  });
}

export function getUniqueTags() {
  return Array.from(
    new Set(
      Activity.all()
        .filter((activity) => activity['tags'])
        .map((activity) => activity['tags'])
        .flat()
    )
  );
}

let tags = [];
export function getUniqueTagsOnce() {
  if (tags.length === 0) {
    tags = Array.from(
      new Set(
        Activity.all()
          .filter((activity) => activity['tags'])
          .map((activity) => activity['tags'])
          .flat()
      )
    );
  }
  return tags;
}

export function getTextEditorAllowedLanguages() {
  const adminTextEditorRoleId = Role.all().find((role) => role['name'] === Roles.ADMIN_TEXT_EDITOR)['_id'];
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.roles.find((role) => role.id === adminTextEditorRoleId)?.adminTextEditorLanguages;
}

export function getUserRolesIds() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser.value?.roles.map((role) => role.id);
}

export function hasRole(roleName) {
  const roleId = Role.all().find((role) => role['name'] === roleName)?.['_id'];
  const userRolesIds = getUserRolesIds();
  return userRolesIds && userRolesIds.includes(roleId);
}

// export function isAdminUser() {
//   const roleId = Role.all().find((role) => role['name'] === Roles.ADMIN)['_id'];
//   const { user: currentUser } = useState('auth', ['user']);
//   return hasRoleInUserOrOrg(currentUser, roleId);
// }

// We are add this tempContributor for only few weeks due to issue in contributor role.

export function isTmpContributor() {
  return hasRole(Roles.TMP_CONTRIBUTOR);
}

export function isAdminUser() {
  const roleId = Role.all().find((role) => role['name'] === Roles.ADMIN)['_id'];
  const { user: currentUser } = useState('auth', ['user']);
  return hasRoleInUserOrOrg(currentUser, roleId) || isTmpContributor();
}

export function isTextEditor() {
  return hasRole(Roles.ADMIN_TEXT_EDITOR);
}

export function isContributor() {
  return hasRole(Roles.COGNISHINE_CONTRIBUTOR);
}

export function isOrgContributor() {
  return hasRole(Roles.ORG_CONTRIBUTOR);
}

export const isUserHasOrganization = (user = null) => {
  if (user) {
    return (user !== null && user?.organization !== null && typeof user?.organization === 'object') === true;
  }
  const { user: userFromState } = useState('auth', ['user']);

  return (
    (userFromState.value !== null &&
      userFromState.value?.organization !== null &&
      typeof userFromState.value?.organization === 'object') === true
  );
};

export function getUserOrganization() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.organization;
}

export function disableLang(lang) {
  if (isAdminUser() || isContributor()) return false;
  const allowedLanguages = getTextEditorAllowedLanguages();
  if (!allowedLanguages) return true;
  return !allowedLanguages.includes(lang);
}

function flatArticulationMetadata(activity) {
  const results = [
    ...activity.metadata?.blend?.map((blend) => {
      return { media: blend?.media, audio: blend?.audio };
    }),
    // .flat()
    ...activity.metadata?.syllable?.map((syllable) => {
      return { media: syllable?.media, audio: syllable?.audio };
    }),
    // .flat(),
  ];
  return results;
}

export const checkActivityPublishedInLanguage = (lang, activityPublished) => {
  const languages = (
    LanguagesAlongWithWebsite.query()
      .all()
      ?.find((language: any) => language?.languageCode === lang) as any
  )?.website.find((website) => activityPublished?.includes(website._id));
  if (languages) return true;
  return false;
};

export const getWebsiteFromLanguage = (lang) => {
  return (
    LanguagesAlongWithWebsite.query()
      .all()
      ?.find((language: any) => language?.languageCode === lang) as any
  )?.website.map((website) => {
    return website._id;
  });
};

export const getAvailableWebsite = () => {
  const availableWebsite = [];
  LanguagesAlongWithWebsite.query()
    .all()
    ?.map((lang: any) => {
      if (lang.website.length) {
        lang.website.forEach((website: any) => {
          availableWebsite.push({ ...website, lang: lang?.languageCode, locale: `${lang.languageCode} - ${website.website}` });
        });
      }
    });
  return availableWebsite;
};

export const getLanguageObjectByLanguageCode = (languageCode: string) => {
  const allLanguagesWithWebsite = LanguagesAlongWithWebsite.query().all();
  const lang = allLanguagesWithWebsite?.find((lang: any) => lang.languageCode === languageCode);
  return lang;
};

export function hasNewMedia(activity, articulationActivity = false) {
  let metadataToPrepare = [];
  if (activity) metadataToPrepare = activity.metadata.slides;
  if (articulationActivity) metadataToPrepare = flatArticulationMetadata(activity);

  for (const slide of metadataToPrepare) {
    if (slide.media) {
      for (const media of slide.media) if (media.hasMedia) return true;
    }
    if (slide.audio) {
      for (const audio of slide.audio) if (audio.hasMedia) return true;
    }
    if (slide.alternateAudio) {
      for (const audio of slide.alternateAudio) if (audio.hasMedia) return true;
    }
    if (slide.activityPlaningItems) {
      for (const planingItem of slide.activityPlaningItems) {
        if (planingItem?.media?.hasMedia) {
          return true;
        }
      }
    }
    if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
      if (slide?.wordDefItems && slide?.wordDefItems?.length) {
        for (const wordDefItem of slide?.wordDefItems) {
          for (const wordSubDefItem of wordDefItem) {
            for (const langKey of Object.keys(wordSubDefItem?.phrases || {})) {
              if (wordSubDefItem?.phrases[langKey]?.isChanged) {
                return true;
              }
            }
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
      for (const langKey of Object.keys(slide?.speechSoundPictureScenesItems?.phrases || {})) {
        for (const subItem of slide?.speechSoundPictureScenesItems?.phrases[langKey]) {
          if (subItem?.isChanged) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export function prepareUploadFiles(activity, dropperType = DROPPER_TYPE.activity) {
  const formData = new FormData();
  let metadataToPrepare = [];
  if (activity && (dropperType as any) === DROPPER_TYPE.activity) metadataToPrepare = activity.metadata.slides;
  if ((dropperType as any) === DROPPER_TYPE.articulation) metadataToPrepare = flatArticulationMetadata(activity);
  for (const slide of metadataToPrepare) {
    if (slide.media) {
      for (const media of slide.media) {
        if (media?.file != null) {
          formData.append('mediaArray', media.file, media.name);
        }
        if (media?.videoThumbnail != null) {
          formData.append('mediaArray', media.videoThumbnail, `thumbnail_${media.name}`);
        }
      }
    }
    if (slide?.audio) {
      for (const audio of slide?.audio) {
        if (audio?.file != null) {
          formData.append('mediaArray', audio.file, audio.name);
        }
      }
    }
    if (slide?.alternateAudio) {
      for (const audio of slide?.alternateAudio) {
        if (audio?.file != null) {
          formData.append('mediaArray', audio.file, audio.name);
        }
      }
    }
    if (slide.activityPlaningItems) {
      for (const planingItem of slide.activityPlaningItems) {
        if (planingItem?.media?.file != null) {
          formData.append('mediaArray', planingItem?.media?.file, planingItem?.media?.name);
        }
      }
    }
    if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
      if (slide?.wordDefItems && slide?.wordDefItems?.length) {
        for (const wordDefItem of slide?.wordDefItems) {
          for (const wordSubDefItem of wordDefItem) {
            for (const langKey of Object.keys(wordSubDefItem?.phrases || {})) {
              if (wordSubDefItem?.phrases[langKey]?.isChanged && wordSubDefItem?.phrases[langKey]?.audioString) {
                const blobFile = base64toBlob(
                  wordSubDefItem?.phrases[langKey]?.audioString,
                  wordSubDefItem?.phrases[langKey]?.audioName,
                  'audio/mpeg'
                );
                if (blobFile) {
                  formData.append('mediaArray', blobFile, wordSubDefItem?.phrases[langKey]?.audioName);
                }
              }
            }
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
      if (slide?.speechSoundPictureScenesItems) {
        for (const langKey of Object.keys(slide.speechSoundPictureScenesItems?.phrases || {})) {
          slide.speechSoundPictureScenesItems?.phrases[langKey].forEach((ele: any) => {
            if (ele?.isChanged && ele?.audioString) {
              const blobFile = base64toBlob(ele?.audioString, ele?.audioName, 'audio/mpeg');
              if (blobFile) {
                formData.append('mediaArray', blobFile, ele?.audioName);
              }
            }
          });
        }
      }
    }
  }

  return formData;
}

export function base64toBlob(base64String: string, fileName: string, fileType: string) {
  try {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new File([new Blob([byteArray])], fileName, { type: fileType });
  } catch (error) {
    console.error('Error decoding base64 string:', error);
    return null;
  }
}

export function deleteActivityMedia(activity, articulationActivity = false) {
  let metadataToPrepare = [];
  if (activity) metadataToPrepare = activity.metadata.slides;
  if (articulationActivity) metadataToPrepare = flatArticulationMetadata(activity);

  metadataToPrepare
    .map((slide) => slide.media)
    .flat()
    .map((media) => {
      if (media?.videoThumbnail) delete media.videoThumbnail;
      if (media?.src) delete media.src;
      if (media?.originalImageThumbnail) delete media.originalImageThumbnail;
    });
  metadataToPrepare
    .map((slide) => slide.audio)
    .flat()
    .map((audio) => {
      if (audio?.src) delete audio.src;
    });

  if (activity.type.name === ActivityTypes.ActivityPlanning) {
    metadataToPrepare
      ?.map((slide) => slide.activityPlaningItems)
      ?.flat()
      ?.map((el) => el.media)
      ?.filter((el) => el)
      ?.map((el) => {
        if (el.originalImageThumbnail) delete el.originalImageThumbnail;
      });
  }

  if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.wordDefItems) {
        slide.wordDefItems.forEach((wordDefItem: any) => {
          wordDefItem.forEach((wordDefSubItem: any) => {
            for (const langKey of Object.keys(wordDefSubItem?.phrases || {})) {
              if (wordDefSubItem?.phrases[langKey]?.audioString || wordDefSubItem?.phrases[langKey]?.audioString === '') {
                delete wordDefSubItem?.phrases[langKey]?.audioString;
              }
              if (wordDefSubItem?.phrases[langKey]?.audioName === '') {
                delete wordDefSubItem?.phrases[langKey]?.audioName;
              }
            }
          });
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.speechSoundPictureScenesItems) {
        for (const langKey of Object.keys(slide?.speechSoundPictureScenesItems?.phrases || {})) {
          slide?.speechSoundPictureScenesItems?.phrases[langKey].forEach((ele: any) => {
            if (ele?.audioString || ele?.audioString === '') {
              delete ele?.audioString;
            }
            if (ele?.audioName === '') {
              delete ele?.audioName;
            }
          });
        }
      }
    });
  }
}

export function removeAudioExtraDetails(activity) {
  if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
    activity.metadata.slides.forEach((slide: any) => {
      if (slide && slide?.wordDefItems) {
        slide.wordDefItems.forEach((wordDefItem: any) => {
          wordDefItem.forEach((wordDefSubItem: any) => {
            for (const langKey of Object.keys(wordDefSubItem?.phrases || {})) {
              if (wordDefSubItem?.phrases[langKey]?.audioName === '') {
                delete wordDefSubItem?.phrases[langKey]?.audioName;
              }
              delete wordDefSubItem.phrases[langKey].audioString;
              delete wordDefSubItem.phrases[langKey].isChanged;
            }
          });
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
    activity.metadata.slides.forEach((slide: any) => {
      if (slide && slide?.speechSoundPictureScenesItems) {
        for (const langKey of Object.keys(slide?.speechSoundPictureScenesItems?.phrases || {})) {
          slide?.speechSoundPictureScenesItems?.phrases[langKey].forEach((ele: any) => {
            if (ele?.audioName === '') {
              delete ele?.audioName;
            }
            delete ele.audioString;
            delete ele.isChanged;
          });
        }
      }
    });
  }

  return true;
}

export function validateImages(activity, articulationActivity = false) {
  let metadataToPrepare = [];
  if (activity) metadataToPrepare = activity?.metadata?.slides;
  if (articulationActivity) metadataToPrepare = flatArticulationMetadata(activity);

  for (const slide of metadataToPrepare) {
    if (slide?.media && slide?.media.length) {
      for (const media of slide?.media) {
        if (media.valid != null && !media.valid) {
          return false;
        }
      }
    }
  }
  return true;
}

export async function getActivity(id) {
  const getActivityApi = usePromise((id) => findActivityOne(id), { lazy: true });
  return (await getActivityApi.exec(id)).data;
}

export async function getArticulation(id) {
  const getArticulationApi = usePromise((id) => findArticulationOne(id), { lazy: true });
  return (await getArticulationApi.exec(id)).data;
}

export function getMultilanguageFieldText(value) {
  if (ALLOWED_LANGUAGES.en in value && value[ALLOWED_LANGUAGES.en]) {
    return value[ALLOWED_LANGUAGES.en];
  }
  if (ALLOWED_LANGUAGES.he in value && value[ALLOWED_LANGUAGES.he]) {
    return value[ALLOWED_LANGUAGES.he];
  }
  if (ALLOWED_LANGUAGES.ar in value && value[ALLOWED_LANGUAGES.ar]) {
    return value[ALLOWED_LANGUAGES.ar];
  }
  if (ALLOWED_LANGUAGES.de in value && value[ALLOWED_LANGUAGES.de]) {
    return value[ALLOWED_LANGUAGES.de];
  }
  if (ALLOWED_LANGUAGES.es in value && value[ALLOWED_LANGUAGES.es]) {
    return value[ALLOWED_LANGUAGES.es];
  }
  if (ALLOWED_LANGUAGES.be in value && value[ALLOWED_LANGUAGES.be]) {
    return value[ALLOWED_LANGUAGES.be];
  }
  if (ALLOWED_LANGUAGES.ru in value && value[ALLOWED_LANGUAGES.ru]) {
    return value[ALLOWED_LANGUAGES.ru];
  }
}

export function getChangedObj(oldObj: object, newObj: object) {
  const changedObj = {};
  Object.keys(newObj).forEach((key) => {
    if (oldObj[key] != newObj?.[key] && newObj?.[key] != null) {
      changedObj[key] = newObj[key];
    }
  });
  return changedObj;
}

export function getActivityAudioPath(name: string, activity: any) {
  if (!name) {
    return null;
  }
  const urlPath = `${environment.cdn}/${activity.type.domain.name.toLowerCase()}/${activity.type.name.toLowerCase()}/${
    activity._id
  }/__FILE__`;
  return getAudioPath(name, urlPath);
}

export function getAudioPath(name: string, urlPrefix: string) {
  if (!name) return null;
  if (name.includes('.mp3')) name = name.replace('.mp3', '');
  return urlPrefix.replace('__FILE__', 'content/audio/' + dasherize(name.replace(/\s/g, '-')) + '.mp3' + forceUpdate());
}

export const forceUpdate = () => {
  return `?GUID=${moment().valueOf()}`;
};

export function getErrorMessage(apiResponse) {
  let errorMsg = '';
  apiResponse.map((errMessage) => {
    errorMsg = errorMsg + '<p>' + `${errMessage} ` + '</p>';
  });
  return errorMsg;
}

export const isImageBasedActivity = (mediaType) => {
  return [ACTIVITY_ENUM.mediaType.photo, ACTIVITY_ENUM.mediaType.isolatePhoto, ACTIVITY_ENUM.mediaType.illustration].includes(mediaType);
};

export const checkNameRecallPhaseValidation = (activity) => {
  const slides = activity?.metadata?.slides;
  const slidesExcludingSummary = slides?.slice(0, activity?.metadata?.slides?.length - 1);

  if (activity?.metadata?.isAlternateTextOrAudio && isImageBasedActivity(activity.mediaType)) {
    return slides.length
      ? !slidesExcludingSummary?.some(
          (el) =>
            Object.keys(el?.phrases).sort().join(',') !== activity.languageCode.sort().join(',') ||
            Object.keys(el?.alternatePhrases).sort().join(',') !== activity.languageCode.sort().join(',')
        ) &&
          !slidesExcludingSummary?.some(
            (el) =>
              Object.values(el?.phrases).includes('') ||
              Object.values(el?.phrases).includes(null) ||
              Object.values(el?.alternatePhrases).includes(null) ||
              Object.values(el?.alternatePhrases).includes('')
          )
      : false;
  }
  if (activity?.metadata?.isAlternateTextOrAudio && activity.mediaType === ACTIVITY_ENUM.mediaType.video) {
    return slides.length
      ? !slidesExcludingSummary?.some(
          (el) => Object.keys(el?.alternatePhrases).sort().join(',') !== activity.languageCode.sort().join(',')
        ) &&
          !activity?.metadata?.slides
            ?.slice(0, activity?.metadata?.slides?.length - 1)
            .some((el) => Object.values(el?.alternatePhrases).includes('') || Object.values(el?.alternatePhrases).includes(null))
      : false;
  }
  if (!activity?.metadata?.isAlternateTextOrAudio && isImageBasedActivity(activity.mediaType)) {
    return slides.length
      ? !slidesExcludingSummary?.some((el) => Object.keys(el?.phrases).sort().join(',') !== activity.languageCode.sort().join(',')) &&
          !slidesExcludingSummary?.some((el) => Object.values(el?.phrases).includes('') || Object.values(el?.phrases).includes(null))
      : false;
  }

  return true;
};

export const checkNameRecallSlideMediaValidation = (activity) => {
  return activity?.metadata?.slides.length
    ? !activity?.metadata?.slides
        ?.slice(0, activity?.metadata?.slides?.length - 1)
        .some((el) => !el?.media?.length || el?.media?.length > 1)
    : false;
};

export const checkNameRecallSummarySlideValidation = (activity) => {
  const mediaPerLevel = {
    1: 2,
    2: 2,
    3: 5,
    4: 5,
    5: 12,
    6: 12,
    7: 10,
    8: 10,
    9: 15,
    10: 15,
  };
  return {
    status:
      !mediaPerLevel[Number(activity.level)] || !activity?.metadata?.slides[activity?.metadata?.slides?.length - 1]?.media?.length
        ? false
        : mediaPerLevel[Number(activity.level)] === activity?.metadata?.slides[activity?.metadata?.slides?.length - 1]?.media?.length,
    numberOfImages: !mediaPerLevel[Number(activity.level)] ? '' : mediaPerLevel[Number(activity.level)],
  };
};

export async function getAllowedLanguage() {
  const { user: currentUser } = useState('auth', ['user']);
  const getRoles = Role.all();

  const userLanguage = [];
  getRoles.forEach((role: any) => {
    if (role.name === Roles.COGNISHINE_CONTRIBUTOR || role.name === Roles.ADMIN_TEXT_EDITOR) {
      currentUser.value.roles.forEach((userRole) => {
        if (userRole.id === role._id && userRole.allowedLanguages && userRole.allowedLanguages.length > 0) {
          userRole.allowedLanguages.forEach((language) => {
            if (!userLanguage.includes(language)) {
              userLanguage.push(language);
            }
          });
        }
      });
    }
  });
  return userLanguage;
}

export const getAvailableLanguages = () => {
  const languages = [];
  LanguagesAlongWithWebsite.query()
    .newQuery()
    .withAllRecursive()
    .all()
    .map((el) => languages.push((el as any).languageCode));

  return languages;
};

const AVAILABLE_ACTIVITY_FOR_AUDIO_KEY_CHANGE = [
  {
    type: ActivityTypes.Comprehension,
    isMultiple: false,
  },
  {
    type: ActivityTypes.IdentifyingEmotions,
    isMultiple: false,
  },
  {
    type: ActivityTypes.NameRecall,
    isMultiple: false,
  },
  {
    type: ActivityTypes.AuditoryComprehensionWord,
    isMultiple: false,
  },
  {
    type: ActivityTypes.AuditoryComprehensionSentence,
    isMultiple: false,
  },
  {
    type: ActivityTypes.Naming,
    isMultiple: true,
  },
];
export const needToChangeAudioField = (currentActivityType) => {
  return !!AVAILABLE_ACTIVITY_FOR_AUDIO_KEY_CHANGE.find((activityType) => activityType.type === currentActivityType);
};

export const isAvailableForMultipleUpload = (currentActivityType) => {
  return !!AVAILABLE_ACTIVITY_FOR_AUDIO_KEY_CHANGE.find(
    (activityType) => activityType.type === currentActivityType && activityType.isMultiple
  );
};

export const checkIsSelectedTargetIsBin = (val, activity) => {
  return activity.metadata.slides.find((slide) => {
    if (slide?.activityPlaningColumnType === ACTIVITY_PLANNING_COLUMN_TYPE.bin) {
      return Object.entries(slide.phrases[0]).find(([key, value]) => {
        if (val) {
          return value === val;
        }
      });
    }
  });
};

export const deleteNotToStoreDataFromNewAudioObj = (audioObj) => {
  const obj = JSON.parse(JSON.stringify(audioObj));

  delete obj.audioString;
  delete obj.isChanged;
  return obj;
};

export const getSlideCountError = (activityType) => {
  const activityErrors = {
    [ActivityTypes.StorySequence]: `${ACTIVITY_ENUM?.errorType?.slideCount} ${STORY_SEQUENCE_ACTIVITY_CONFIG?.slideCount?.minSlideCount} to ${STORY_SEQUENCE_ACTIVITY_CONFIG?.slideCount?.maxSlideCount}.`,
  };
  return activityErrors[activityType];
};

//TODO: remove this function
//INFO: This function is used for temporary solution to disable auto logout for given list of developers
export const isRegisterAutoLogoutListner = (email) => {
  const allowedEmails = [
    'milan.rabara@cognishine.com',
    'ido@cognishine.com',
    'gennady.treibich@cognishine.com',
    'parshv.s@cognishine.com',
    'alfina.mansuri@cognishine.com',
    'sarang.tandel@cognishine.com',
  ];

  return !allowedEmails.includes(email);
};

export const isActivitySlideCountInvalid = (slideLength) => {
  return slideLength >= STORY_SEQUENCE_ACTIVITY_CONFIG.slideCount.minSlideCount &&
    slideLength <= STORY_SEQUENCE_ACTIVITY_CONFIG.slideCount.maxSlideCount
    ? false
    : true;
};
