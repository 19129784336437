import { ALLOWED_LANGUAGES } from './activity';
import mongoose, { Document, Schema } from 'mongoose';

export const CLIENT_ENUM = {
  defaultLanguageCode: Object.values(ALLOWED_LANGUAGES),
};

//

export interface Client {
  name: string;
}

export interface MongooseClient extends Client, Document {}

const ClientSchema = new Schema(
  {
    organizationId: { type: Schema.Types.ObjectId, ref: 'Organization' },
    identifier: { type: String, index: true },
    name: { type: String, index: true },
    email: { type: String },
    dataCollectionEnabled: { type: Boolean },
    active: { type: Boolean },
    defaultLanguageCode: {
      type: String,
      enum: CLIENT_ENUM.defaultLanguageCode,
    },
    comments: { type: String },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
    profileImage: { type: String, default: null },
    preferences: {
      phoneticsListOrder: {
        type: Object,
      },
    },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const Client = mongoose.model<MongooseClient>(
  'Client',
  ClientSchema
) as any;
