import { Model } from '@vuex-orm/core';

export class Domain extends Model {
  public static entity = 'domains';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
    };
  }
}
