import mongoose, { Document, Schema } from 'mongoose';

export interface PhoneticsSounds {}

export interface MongoosePhoneticsSounds extends PhoneticsSounds, Document {}

export const PHONETICS_SOUND_TYPE = ['vowel', 'consonant'];

const phoneticsSoundsSchema = new Schema(
  {
    type: {
      type: String,
      default: null,
      enum: PHONETICS_SOUND_TYPE,
    },
    isHidden: { type: Boolean, default: false },
    symbol: { type: String, default: null },
    displayText: { type: String, default: null },
    example: { type: String, default: null },
    language: { type: Schema.Types.ObjectId, ref: 'Language' },
    roles: [{ type: Schema.Types.ObjectId, ref: 'Role' }],
    group: {
      type: String,
      default: null,
      enum: ['Stops', 'Fricatives', 'Liquids', 'Glides'],
    },
    weight: {
      type: Number,
      default: null,
    },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const PhoneticsSounds = mongoose.model<MongoosePhoneticsSounds>(
  'Phonetics-Sounds',
  phoneticsSoundsSchema
) as any;
