import mongoose, { Document, Schema } from 'mongoose';

export interface ContentType {
  key: string;
  value: any;
}

export interface MongooseContentType extends ContentType, Document {}

const ContentTypeSchema = new Schema(
  {
    key: { type: String, unique: true, index: true },
    value: { type: Schema.Types.Mixed },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const ContentType = mongoose.model<MongooseContentType>(
  'Content-Type',
  ContentTypeSchema
) as any;
