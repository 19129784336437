import Vue from 'vue';
import Vuex, { Store, Plugin } from 'vuex';
import VuexORM from '@vuex-orm/core';

import * as fromEntities from './entities';

// import { createStore as SmartStore } from 'vuex-smart-module';

// import { Root, RootState } from './modules/root';

import app from './modules/app';
import auth from './modules/auth';
import pathify from 'vuex-pathify';

// pathify.debug();

Vue.use(Vuex);

export function createStore() {
  // const plugins: Array<Plugin<RootState>> = [];

  const database = new VuexORM.Database();

  Object.keys(fromEntities).forEach((entity) => database.register((fromEntities as any)[entity]));

  // plugins.push(VuexORM.install(database));

  // return SmartStore(Root, {
  //   strict: false,
  //   plugins,
  // }) as Store<RootState>;

  return new Vuex.Store({
    modules: {
      app,
      auth,
    },
    plugins: [VuexORM.install(database), pathify.plugin],
  });
}
