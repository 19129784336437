import { Model } from '@vuex-orm/core';

export class Board extends Model {
  public static entity = 'board';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      user: this.attr(null),
      items: this.attr([]),
    };
  }
}
