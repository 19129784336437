import goTo from 'vuetify/es5/services/goto';
import { waitForReadystate } from '../utils/helpers';
import { window } from 'global';

export default async function (to: any, from: any, savedPosition: any): Promise<void> {
  console.log('---in scroll beh before waitForReady');
  await waitForReadystate();
  console.log('--in scrolll after');

  let scrollTo = 0;
  const options = { duration: 0 };

  if (to.hash) {
    scrollTo = to.hash;
  } else if (savedPosition) {
    scrollTo = savedPosition.y;
  }

  return new Promise<void>((resolve) => {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        try {
          goTo(scrollTo, options);
        } catch (err) {
          console.log(err);
        }

        resolve();
      });
    }, 200);
  });
}
