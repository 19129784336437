import { Model } from '@vuex-orm/core';

export class Survey extends Model {
  public static entity = 'survey';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      header: this.attr(null),
      notificationSurvey: this.attr(false),
      active: this.attr(false),
      startDate: this.attr(null),
      endDate: this.attr(null),
      description: this.attr(null),
      sortPriority: this.attr(null),
      questions: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      notificationSettings: this.attr(null),
      pinned: this.attr(null),
      adminOnly: this.attr(false),
      adminTestNotificationConfig: this.attr(false),
    };
  }
}
