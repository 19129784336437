import bcrypt from 'bcryptjs';
import { omit } from 'lodash';
import mongoose, { Document, Schema } from 'mongoose';
import { ACTIVITY_ENUM } from './activity';

// tslint:disable:only-arrow-functions

const BCRYPT_SALT_ROUNDS = 13; // strong, but not too much, ~1 hash/sec

export interface MongooseUser extends /* user,*/ Document {
  hasPermission(permission: string): boolean;
  hasPermissions(...permissions: string[]): boolean;

  hashPassword(password: string): Promise<string>;
  verifyPassword(password: string): Promise<boolean>;
}

const privates = [
  '__v',
  'password',
  'facebook',
  'apple',
  'linkedin',
  'google' /*'members'*/,
  ,
  'created_at',
  'updated_at',
  'active',
];

export const organizationRoles = {
  ADMIN: {
    name: 'Admin',
    value: 1,
  },
  CONTENT_MANAGER: {
    name: 'Content Manager',
    value: 2,
  },
  VIEWER: {
    name: 'Viewer',
    value: 3,
  },
};

export enum occupation {
  SLP = 'slp',
  OT = 'ot',
  Teacher = 'teacher',
  Other = 'other',
}

export const USER_ENUM = {
  organizationRole: [
    organizationRoles.ADMIN.value,
    organizationRoles.CONTENT_MANAGER.value,
    organizationRoles.VIEWER.value,
  ].concat([null]),
  occupationType: Object.values(occupation),
};

export const USER_SELF_PRACTICE_LIMIT = {
  userSelfPracticeLimit: 20,
  userSelfPracticeMonthlyLimit: 20,
};

export const USER_PRINTS_LIMIT = {
  userPrintLimit: 30,
  userPrintsLeftThisMonth: 30,
};

export enum emailVerifyInOrg {
  VERIFIED = 'verified',
  PENDING = 'pending',
}

export const ORG_EMAIL_VERIFY_ENUM = {
  organizationStatus: Object.values(emailVerifyInOrg),
};

export const excludeUserKeysForApp = [
  'isPaying',
  'comments',
  'isVerifyInOrganization',
  'purchaseComments',
  'selectedClientAt',
  'surveyNotificationsStatistics',
  'google',
];
const UserSchema = new Schema(
  {
    email: {
      type: String,
      required: true,
      unique: true,
      lowercase: true,
      trim: true,
    },
    organization: { type: Schema.Types.ObjectId, ref: 'Organization' },
    name: { type: String, required: true },
    password: { type: String },
    picture: { type: String },
    verified: { type: Boolean, default: false },
    active: { type: Boolean, default: true },
    externalPaymentPlan: { type: Boolean, default: false },
    isPaying: { type: Boolean, default: false },
    freeTrialRedeemDate: { type: Date, default: null },
    licenses: { type: Number },
    plan: { type: Schema.Types.Mixed },
    purchase: { type: Schema.Types.ObjectId, ref: 'Purchase' },
    lastSelectedClientId: { type: Schema.Types.ObjectId, ref: 'Client' },
    selectedClientAt: { type: Date },
    team: { type: Schema.Types.Mixed },
    roles: [
      {
        id: { type: Schema.Types.ObjectId, ref: 'Role' },
        expirationDate: { type: Date },
        adminTextEditorLanguages: { type: [String], default: null },
        allowedLanguages: { type: [String], default: [] },
      },
    ],
    comments: { type: String },
    purchaseComments: { type: String },

    members: [
      {
        name: { type: String, required: true, trim: true },
        email: {
          type: String,
          required: true,
          lowercase: true,
          set: (v) => (!v || !v.length ? undefined : v),
          index: { unique: true, sparse: true },
        },
        status: {
          type: String,
          required: true,
          enum: ['PENDING', 'ACTIVATED'],
          default: 'PENDING',
        },
      },
    ],
    surveyNotificationsStatistics: {
      lastSurveyNotificationActionDate: { type: Date },
    },
    surveyStatistics: [
      {
        surveyId: { type: Schema.Types.ObjectId, ref: 'Survey', unique: true },
        notificationLastPresentedDate: { type: Date },
        notificationLastActionDate: { type: Date },
        submissionDate: { type: Date },
        notificationPresentedCount: { type: Number, default: 0 },
        notificationCanceledCount: { type: Number, default: 0 },
        notificationActionCount: { type: Number, default: 0 },
        submitted: { type: Boolean, default: false },
      },
    ],
    preferences: {
      printsLimit: { type: Number, default: USER_PRINTS_LIMIT.userPrintLimit },
      printsLeftThisMonth: {
        type: Number,
        default: USER_PRINTS_LIMIT.userPrintsLeftThisMonth,
      },
      homePracticesShareCount: { type: Number, default: 0 },
      mediaQuailty: { type: String },
      videoQuailty: { type: String },
      installMessage: { type: Boolean, default: true },
      newsletters: { type: Boolean, default: false },
      defaultLanguageCode: {
        type: String,
        enum: ACTIVITY_ENUM.languageCode,
        default: 'en',
      },
      defaultWebsiteCode: {
        type: Schema.Types.ObjectId,
        ref: 'Website',
        default: null,
      },
      showClientCue: { type: Boolean, default: true },
      showClientAnimationBeforeActivity: { type: Boolean, default: true },
      showExplosionsFeedbacks: { type: Boolean, default: true },
      playFeedbackSound: { type: Boolean, default: true },
      keepMeLoggedIn: { type: Boolean, default: true },
      phoneticsListOrder: {
        type: Object,
      },
      hasAllowedMeasurementCookies: { type: Boolean, default: null },
    },
    apple: {
      id: { type: String },
      token: { type: String },
      refreshToken: { type: String },
      code: { type: String },
      _raw: { type: String },
    },
    linkedin: {
      id: { type: String },
      token: { type: String },
      refreshToken: { type: String },
      code: { type: String },
      _raw: { type: String },
    },
    google: {
      id: { type: String },
      token: { type: String },
      refreshToken: { type: String },
      code: { type: String },
      _raw: { type: String },
    },
    facebook: {
      id: { type: String },
      token: { type: String },
      refreshToken: { type: String },
      code: { type: String },
      _raw: { type: String },
    },
    removedRecentUsersIds: [{ type: Schema.Types.ObjectId, ref: 'User' }],
    organizationRole: { type: Number, enum: USER_ENUM.organizationRole },
    occupation: { type: String, enum: USER_ENUM.occupationType },
    selfPractice: {
      selfPracticeShareLimit: {
        type: Number,
        default: USER_SELF_PRACTICE_LIMIT.userSelfPracticeLimit,
      },
      shareLeftThisMonth: {
        type: Number,
        default: USER_SELF_PRACTICE_LIMIT.userSelfPracticeMonthlyLimit,
      },
    },
    organizationStatus: {
      type: String,
      enum: [...ORG_EMAIL_VERIFY_ENUM.organizationStatus, null],
      default: emailVerifyInOrg.PENDING,
    },
    selfCreated: {
      type: Boolean,
      default: true,
    },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

UserSchema.pre('save', async function (this: Document, next): Promise<void> {
  const that = this as any; //MongooseUser;
  if (this.isNew || this.isModified('password')) {
    that.password = await that.hashPassword(that.password);
  }

  next();
});

// UserSchema.pre('findOneAndUpdate', function () {
//   const update = this?.getUpdate();

//   if (update && update?.$set?.role === null) {
//     update.$unset = { role: 1 };
//     delete update.$set.role;
//     (this as any).setUpdate(update);
//   }
// });

UserSchema.set('toJSON', {
  transform: function (doc, ret) {
    doc;
    ret.id = ret._id;
    return omit(ret, privates);
  },
});

UserSchema.methods.hashPassword = async function (
  this: MongooseUser,
  password: string
): Promise<string> {
  return bcrypt.hash(password, BCRYPT_SALT_ROUNDS);
};

UserSchema.methods.verifyPassword = async function (
  this: MongooseUser,
  password: string
): Promise<boolean> {
  return bcrypt.compare(password, (this as any).password);
};

export const User = mongoose.model<MongooseUser>('User', UserSchema) as any;
