import mongoose, { Document, Schema } from 'mongoose';

export interface ActivityBackup {
  activityId: Schema.Types.ObjectId;
  activity: any;
}

export interface MongooseActivityBackup extends ActivityBackup, Document {}

const activityBackupSchema = new Schema({
  activityId: {
    type: mongoose.Schema.Types.ObjectId,
    ref: 'Activity',
    default: null,
  },
  activity: { type: Object },
  createdAt: { type: Date, default: Date.now },
});

export const ActivityBackup = mongoose.model<MongooseActivityBackup>(
  'Activity-Backup',
  activityBackupSchema
) as any;
