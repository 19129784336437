import mongoose, { Document, Schema } from 'mongoose';
import { PHONETICS_WORDS } from './phonetics-words';

export interface PhoneticsLists {}

export interface MongoosePhoneticsLists extends PhoneticsLists, Document {}

export const PHONETICS_LISTS = {
  orderListOption: {
    moveUp: 'MOVE_UP',
    moveDown: 'MOVE_DOWN',
  },
};

export const PHONETIC_LIST_TYPE = {
  MY_LIST: 1,
  ORGANIZATION_LIST: 2,
  CLIENT_LIST: 3,
};

const phoneticsListsSchema = new Schema(
  {
    name: { type: String, required: true },
    user: { type: Schema.Types.ObjectId, ref: 'User' },
    organization: {
      type: Schema.Types.ObjectId,
      ref: 'Organization',
      default: null,
    },
    client: {
      type: mongoose.Schema.Types.ObjectId,
      ref: 'Client',
      default: null,
    },
    language: { type: Schema.Types.ObjectId, ref: 'Language' },
    listType: {
      type: Number,
      enum: Object.values(PHONETIC_LIST_TYPE),
      default: PHONETIC_LIST_TYPE.MY_LIST,
    },
    items: [
      {
        wordContentType: {
          type: String,
          enum: Object.values(PHONETICS_WORDS.contentType),
          default: PHONETICS_WORDS.contentType.word,
        },
        word: { type: Schema.Types.ObjectId, ref: 'Phonetics-Words' },
        imageType: {
          type: String,
          enum: Object.values(PHONETICS_WORDS.imageType),
          default: PHONETICS_WORDS.imageType.standard,
        },
      },
    ],
  },
  {
    timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' },
  }
);

export const PhoneticsLists = mongoose.model<MongoosePhoneticsLists>(
  'Phonetics-Lists',
  phoneticsListsSchema
) as any;
