/* eslint-disable */
/* eslint-disable @typescript-eslint/camelcase */
import { Model } from '@vuex-orm/core';
import { ActivityType } from './activity-type';
import { Category } from './category';
import { SubCategory } from './subcategory';

export class Activity extends Model {
  public static entity = 'activities';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      tags: this.attr(null),
      activity_id: this.attr(null),
      name: this.attr(''),
      type: this.belongsTo(ActivityType, 'type_id'),
      type_id: this.attr(''),
      category: this.belongsTo(Category, 'category_id'),
      category_id: this.attr(''),
      subCategory: this.belongsTo(SubCategory, 'subCategory_id'),
      subCategory_id: this.attr(''),
      level: this.attr(null),
      // description: this.attr(null),
      printable: this.attr(null),
      free: this.attr(null),
      editorial: this.attr(null),
      notes: this.attr(null),
      orientation: this.attr(null),
      mediaType: this.attr(null),
      status: this.attr(null),
      audience: this.attr(null),
      targetAudience: this.attr(null),
      sortPriority: this.attr(1),
      groupSortPriority: this.attr(1),
      waitingForAudio: this.attr([]),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      createdBy: this.attr(null),
      ownedBy: this.attr(null),
      submittedAt: this.attr(null),
      targetDomain: this.attr(null),
      languageCode: this.attr(null),
      published: this.attr(null),
      isolate: this.attr(null),
      metadata: this.attr(null),
      attachedRoles: this.attr([]),
      tempAudioGenerated: this.attr(false),
      activityVisibility: this.attr(null),
    };
  }

  get vRoles() {
    const self = this as any;
    return self.roles ? self.roles : false;
  }
}
