import mongoose, { Document, Schema } from 'mongoose';

export enum ACTION_TYPE_ENUM {
  SIGNUP = 'signup',
  LOGIN = 'login',
  LOGOUT = 'logout',
  EMAIL_VERIFIED = 'email verified',
  PLAN_PURCHASED = 'plan purchased',
  PLAN_CANCELLED = 'plan cancelled',
  PING = 'ping',
  STAY_SIGNED_IN = 'stay-signed-in',
}

export const AUDIT_LOG_VERSION = {
  PRIVACY_POLICY: {
    en: 'V1.0.0',
    he: 'V1.0.0',
    ar: 'V1.0.0',
    de: 'V1.0.0',
    es: 'V1.0.0',
    be: 'V1.0.0',
    ru: 'V1.0.0',
  },
  TERMS_OF_USE: {
    en: 'V1.0.0',
    he: 'V1.0.0',
    ar: 'V1.0.0',
    de: 'V1.0.0',
    es: 'V1.0.0',
    be: 'V1.0.0',
    ru: 'V1.0.0',
  },
};

export const USER_LOG_ENUM = {
  actionType: Object.values(ACTION_TYPE_ENUM),
};

export interface UserAuditLog {
  user: any;
  actionType: any;
  description: any;
}

export interface MongooseUserAuditLog extends UserAuditLog, Document {}

const userAuditLogSchema = new Schema({
  user: { type: mongoose.Schema.Types.ObjectId, ref: 'User' },
  actionType: { type: String, enum: USER_LOG_ENUM.actionType },
  admin: { type: Boolean, default: false },
  description: { type: String },
  browserId: { type: String, default: null },
  createdAt: { type: Date, default: Date.now },
  privacyPolicyVersion: { type: String, default: null },
  termsOfUseVersion: { type: String, default: null },
});

export const UserAuditLog = mongoose.model<MongooseUserAuditLog>(
  'User-Audit-Logs',
  userAuditLogSchema
) as any;
