import mongoose, { Document, Schema } from 'mongoose';

export const SURVEY_ENUM = {
  questionFieldType: ['choice', 'text'],
  questionType: ['rating', 'choice', 'multipleChoice', 'openEnded'],
};

export enum questionFieldType {
  CHOICE = 'choice',
  TEXT = 'text',
}

export enum questionType {
  RATING = 'rating',
  CHOICE = 'choice',
  OPEN_ENDED = 'openEnded',
}

export interface Survey {
  name: string;
}

export interface MongooseSurvey extends Survey, Document {}

const SurveySchema = new Schema(
  {
    name: { type: String },
    header: { type: String },
    notificationSurvey: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    pinned: { type: Boolean, default: false },
    adminOnly: { type: Boolean, default: false },
    adminTestNotificationConfig: { type: Boolean, default: false },
    description: { type: String },
    sortPriority: { type: Number, default: 1 },
    notificationSettings: {
      firstNotificationDaysFromCreationDate: { type: Number },
      daysBetweenNotifications: { type: Number, default: 5 },
      maxSurveyNotifications: { type: Number, default: 5 },
    },
    questions: [
      {
        text: { type: String },
        index: { type: Number, default: 1 },
        type: { type: String, enum: SURVEY_ENUM.questionType },
        mandatory: { type: Boolean },
        scalaStartLabel: { type: String },
        scalaEndLabel: { type: String },
        scalaStartNumber: { type: Number },
        scalaEndNumber: { type: Number },
        options: [
          {
            text: { type: String },
            type: { type: String, enum: SURVEY_ENUM.questionFieldType },
          },
        ],
      },
    ],

    submittedAt: { type: Date, default: Date.now },
    startDate: { type: Date, default: Date.now },
    endDate: { type: Date, default: Date.now },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const Survey = mongoose.model<MongooseSurvey>(
  'Survey',
  SurveySchema
) as any;
