import Vue from 'vue';

export const environment = {
  cdn: process.env.VUE_APP_CDN,
  version: process.env.VUE_APP_VERSION,
  env: process.env.VUE_APP_APP_ENV,
};

console.log({ environment });

Vue.prototype.$environment = environment;
