import mongoose, { Document, Schema } from 'mongoose';

export interface MongooseList extends Document {}

export enum availableContextType {
  User = 'User',
  Client = 'Client',
  Organization = 'Organization',
  Board = 'Board',
}

export enum availableOrderType {
  Board = 'Board',
  BoardActivities = 'Activity',
}

const OrderListSchema = new Schema({
  contextId: { type: Schema.Types.ObjectId, required: true, unique: true },
  contextType: {
    type: String,
    enum: Object.values(availableContextType),
  },
  orderType: {
    type: String,
    required: true,
    enum: Object.values(availableOrderType),
  },
  order: [{ type: Schema.Types.ObjectId, default: [] }],
});

export const OrderList = mongoose.model<MongooseList>(
  'Order-List',
  OrderListSchema
) as any;
