import { Model } from '@vuex-orm/core';

export class Articulation extends Model {
  public static entity = 'articulations';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      letter: this.attr(''),
      display: this.attr(''),
      title: this.attr(''),
      description: this.attr(''),
      notes: this.attr(''),
      metadata: this.attr(null),
      category: this.attr(null),
      sound: this.attr(null),
      languageCode: this.attr([]),
      tempAudioGenerated: this.attr(false),
      syllable: this.attr(false),
      blends: this.attr(false),
      attachedRoles: this.attr([]),
      tagInfo: this.attr(null),
      updated_at: this.attr(null),
    };
  }
}
