import mongoose, { Document, Schema } from 'mongoose';
import { Category } from './category';

export interface SubCategory {
  name: Object;
  category: Category;
  types: string[];
  weight: number;
}

export interface MongooseSubCategory extends SubCategory, Document {}

const SubCategorySchema = new Schema(
  {
    name: { type: Object, index: true },
    weight: { type: mongoose.Schema.Types.Number },
    category: { type: Schema.Types.ObjectId, ref: 'Category' },
    types: [{ type: mongoose.Schema.Types.ObjectId, ref: 'Type' }],
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const SubCategory = mongoose.model<MongooseSubCategory>(
  'SubCategory',
  SubCategorySchema
) as any;
