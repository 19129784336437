import mongoose, { Document, Schema } from 'mongoose';
import { Roles } from '@packages/roles';
// import { Roles } from '@cognishine/roles';

export interface Plan {
  name: string;
  price: {
    currency: string;
    figure: number;
    monthlyFigure: number;
  };
  active: boolean;
  category: string;
  recommended: boolean;
  paypalPlanId: string;
  licenses: {
    value: number;
    max?: number;
    min?: number;
  };
  role: any;
}

export interface MongoosePlan extends Plan, Document {}

const PlanSchema = new Schema({
  name: { type: String },
  price: {
    currency: { type: String },
    figure: { type: Number },
    monthlyFigure: { type: Number },
  },
  type: { type: String, enum: ['monthly', 'yearly', 'yearly-billed-monthly'] },
  recommended: { type: Boolean },
  active: { type: Boolean, default: true },
  role: { type: String },
  expires: { type: Number },
  order: { type: Number },
  paypalPlanId: { type: String },
  requires: {
    user: { type: Boolean, default: false },
    verify: { type: Boolean, default: false },
    role: { type: [String], default: null, enum: Object.values(Roles) },
  },

  licenses: {
    value: { type: Number },
    max: { type: Number },
    min: { type: Number },
  },
  coupon: [
    {
      name: { type: String },
      code: { type: String },
      value: { type: Number },
      expireAt: { type: Date },
      paypalPlanId: { type: String },
      couponsUsed: { type: Number, default: 0 },
      couponsCapacity: { type: Number },
    },
  ],
});

export const Plan = mongoose.model<MongoosePlan>('Plan', PlanSchema) as any;
