import mongoose, { Document, Schema } from 'mongoose';

export interface PhoneticsWordsLogs {}

export interface MongoosePhoneticsWordsLogs
  extends PhoneticsWordsLogs,
    Document {}

const PhoneticsWordsLogsSchema = new Schema(
  {
    word: { type: Schema.Types.ObjectId, ref: 'Phonetics-Words' },
    document: { type: Object, default: null },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const PhoneticsWordsLogs = mongoose.model<MongoosePhoneticsWordsLogs>(
  'Phonetics-Words-Logs',
  PhoneticsWordsLogsSchema
) as any;
