import mongoose, { Document, Schema } from 'mongoose';

export const PHONETICS_STRUCTURE = {
  articulationTypes: ['Word', 'Phrase', 'Sentence'],
  status: [
    'Started',
    'WaitingForReview',
    'TechnicalWriterPending',
    'TechnicalWriterApproved',
    'Ready',
    'Published',
  ],
  structure: [
    'CV',
    'CVC',
    'CVCC',
    'CCVC',
    'VC',
    'CVCV',
    'CVCVC',
    'CVCCV',
    'CVCCVC',
    'VCV',
    'VCVC',
    'VCCVC',
    'CVCVCV',
    'CVCVCVC',
    'CVCCVCCV',
    'CVCCVCVC',
  ],
  //
  availableStructuresInLanguages: {
    en: [
      { name: 'CV', free: true, example: 'Go' },

      { name: 'VC', free: true, example: 'up' },

      { name: 'CVC', free: false, example: 'Dog' },

      { name: 'CVCC', free: false, example: 'Pink' },

      { name: 'CCVC', free: false, example: 'Spoon' },

      { name: 'CVCCC', free: false, example: 'pants' },

      { name: 'VCV', free: false, example: 'away' },

      { name: 'CVCV', free: false, example: 'Baby' },

      { name: 'VCVC', free: false, example: 'open' },

      { name: 'CVVC', free: false, example: 'shower' },

      { name: 'CVCVC', free: false, example: 'lemon' },

      { name: 'CVCCV', free: false, example: 'pasta' },

      { name: 'VCCVC', free: false, example: 'on top' },

      { name: 'CVCCVC', free: false, example: 'picnic' },

      { name: 'CVCVCC', free: false, example: 'peanuts' },

      { name: 'CVCCCVC', free: false, example: 'penguin' },

      { name: 'CVCCVCC', free: false, example: 'password' },

      { name: 'CVCVCV', free: false, example: 'banana' },

      { name: 'CVCVCVC', free: false, example: 'ladybug' },

      { name: 'CVCVCCV', free: false, example: 'seventy' },

      { name: 'CVCCVCV', free: false, example: 'pinata' },

      { name: 'CVCVCCVC', free: false, example: 'policeman' },

      { name: 'CVCCVCVC', free: false, example: 'dishwasher' },
    ],

    he: [
      { name: 'CV', free: true, example: 'פה' },
      { name: 'CVC', free: false, example: 'דלי' },
      { name: 'CCV', free: false, example: 'סוס' },
      { name: 'CVCC', free: true, example: 'טוסט' },
      { name: 'CCVC', free: false, example: 'כרוב' },
      { name: 'VC', free: false, example: 'אף' },
      { name: 'VCV', free: false, example: 'אבא' },
      { name: 'CVCV', free: false, example: 'תוכי' },
      { name: 'VCCV', free: false, example: 'אצבע' },
      { name: 'VCVC', free: false, example: 'אטב' },
      { name: 'CVVC', free: false, example: 'שועל' },
      { name: 'VCCVC', free: false, example: 'ארנב' },
      { name: 'CVCVC', free: true, example: 'גזר' },
      { name: 'CVCCV', free: false, example: 'ילדה' },
      { name: 'CCVCV', free: false, example: 'גבינה' },
      { name: 'CCVCVC', free: false, example: 'פרחים' },
      { name: 'CVCCVC', free: false, example: 'ינשוף' },
      { name: 'VCVCV', free: false, example: 'אפונה' },
      { name: 'CVCVV', free: false, example: 'לטאה' },
      { name: 'CVCVCV', free: false, example: 'מדורה' },
      { name: 'CVCVVC', free: false, example: 'תפוח' },
      { name: 'CVCCVCV', free: false, example: 'פשטידה' },
      { name: 'VCVCVC', free: false, example: 'אגוזים' },
      { name: 'CVCVCVC', free: false, example: 'חללית' },
      { name: 'CVCCVCVC', free: false, example: 'שרשרת' },
      { name: 'CVCVCCVC', free: false, example: 'כדורסל' },
    ],
    ar: [
      { name: 'CV', free: true, example: 'لا' },
      { name: 'CVC', free: false, example: 'باب' },
      { name: 'CVCC', free: false, example: 'كلب' },
      { name: 'VC', free: false, example: 'آب' },
      { name: 'CVCV', free: false, example: 'عالي' },
      { name: 'CVCVC', free: false, example: 'جزر' },
      { name: 'CVCCV', free: false, example: 'زهرة' },
      { name: 'CVCCVC', free: false, example: 'فستان' },
      { name: 'VCV', free: false, example: 'آلام' },
      { name: 'VCVC', free: false, example: 'آسيا' },
      { name: 'VCCVC', free: false, example: 'ارنب' },
      { name: 'CVCVCV', free: false, example: 'جميلة' },
      { name: 'CVCVCVC', free: false, example: 'اناناس' },
      { name: 'CVCCVCCV', free: false, example: 'مستشفى' },
      { name: 'CVCCVCVC', free: false, example: 'انتظار' },
    ],
    de: [
      { name: 'CV', free: true, example: 'Tee' },
      { name: 'VC', free: false, example: 'auf' },
      { name: 'CCV', free: false, example: 'grau' },
      { name: 'CVC', free: false, example: 'Buch' },
      { name: 'VCC', free: true, example: 'alt' },
      { name: 'CCVC', free: false, example: 'Schloss' },
      { name: 'CVCC', free: false, example: 'Bank' },
      { name: 'CVCCC', free: false, example: 'Wurst' },
      { name: 'CCVCC', free: false, example: 'krank' },
      { name: 'VCV', free: false, example: 'Affe' },
      { name: 'VCCV', free: false, example: 'Alter' },
      { name: 'VCVC', free: false, example: 'Augen' },
      { name: 'CVCV', free: false, example: 'Reise' },
      { name: 'CVCVC', free: false, example: 'reden' },
      { name: 'CCVCV', free: false, example: 'Blume' },
      { name: 'VCCVC', free: false, example: 'Onkel' },
      { name: 'CVCCV', free: false, example: 'Maske ' },
      { name: 'CCVCVC', free: false, example: 'schlafen' },
      { name: 'CVCCVC', free: false, example: 'Fußball' },
      { name: 'CVCVCC ', free: false, example: 'Geschenk' },
      { name: 'CCCVCVC', free: false, example: 'springen' },
      { name: 'CVCVCV', free: false, example: 'Pullover' },
    ],
    es: [
      { name: 'CV', free: true, example: 'caminar' },
      { name: 'CVC', free: false, example: 'perro' },
      { name: 'CVCC', free: false, example: 'rosas' },
      { name: 'CCVC', free: false, example: 'Löffel' },
      { name: 'VC', free: true, example: 'cuchara' },
      { name: 'CVCV', free: false, example: 'Bebé' },
      { name: 'CVCVC', free: false, example: 'limón' },
      { name: 'CVCCV', free: false, example: 'pasta' },
    ],
    be: [
      { name: 'CV', free: true, example: 'caminar' },
      { name: 'CVC', free: false, example: 'perro' },
      { name: 'CVCC', free: false, example: 'rosas' },
      { name: 'CCVC', free: false, example: 'Löffel' },
      { name: 'VC', free: true, example: 'cuchara' },
      { name: 'CVCV', free: false, example: 'Bebé' },
      { name: 'CVCVC', free: false, example: 'limón' },
      { name: 'CVCCV', free: false, example: 'pasta' },
    ],
    ru: [
      { name: 'CV', free: true, example: 'caminar' },
      { name: 'CVC', free: false, example: 'perro' },
      { name: 'CVCC', free: false, example: 'rosas' },
      { name: 'CCVC', free: false, example: 'Löffel' },
      { name: 'VC', free: true, example: 'cuchara' },
      { name: 'CVCV', free: false, example: 'Bebé' },
      { name: 'CVCVC', free: false, example: 'limón' },
      { name: 'CVCCV', free: false, example: 'pasta' },
    ],
  },

  availableVowelsInLanguages: {
    en: [
      'æ',
      'e',
      'ɑ',
      'ɪ',
      'ʊ',
      'i',
      'eɪ',
      'aɪ',
      'aʊ',
      'oʊ',
      'ʌ',
      'u',
      'ɔ',
      'ɔɪ',
      'ɜː',
      'ə',
      'ɛ',
    ],
    he: ['A', 'E', 'I', 'O', 'U'],
    ar: ['ا', 'و', 'ي', ' َ', ' ُ', ' ِ'],
  },

  errorType: {
    type: 'Please select type',
    limitError: 'Not allowed to add more breakpoint.',
  },
  breakDownType: {
    strong: 'Strong',
    weak: 'Weak',
  },
};

export interface PhoneticsStructure {}

export interface MongoosePhoneticsStructure
  extends PhoneticsStructure,
    Document {}

const phoneticsStructureSchema = new Schema(
  {
    structure: {
      type: String,
      default: null,
      enum: PHONETICS_STRUCTURE.structure,
    },
    published: { type: Boolean, default: false },
    languages: [{ type: Schema.Types.ObjectId, ref: 'Language' }],
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const PhoneticsStructure = mongoose.model<MongoosePhoneticsStructure>(
  'Phonetics-Structure',
  phoneticsStructureSchema
) as any;
