import { Model } from '@vuex-orm/core';
import { Plan } from './plan';
import { Purchase } from './purchase';
import { Role } from './role';
import { ORGANIZATION_SELF_PRACTICE_LIMIT, HOME_PRACTICE_OPTIONS, ORGANIZATION_PRINTS_LIMIT } from '@packages/mongodb';

export class Organization extends Model {
  public static entity = 'organizations';

  public static primaryKey = '_id';

  public static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(''),
      title: this.attr(null),
      contactEmail: this.attr(null),
      logoPath: this.attr(null),
      purchase: this.belongsTo(Purchase, 'purchase_id'),
      plan: this.belongsTo(Plan, 'plan_id'),
      planType: this.attr(null),
      dataCollectionEnabled: this.attr(null),
      adminUsersIds: this.attr([]),
      active: this.attr([]),
      isExternalOrganization: this.attr(false),
      isPaying: this.attr(false),
      // this.hasMany(User, '_id'),
      //this.attr([]),
      selfPractice: this.attr({
        selfPracticeShareLimit: ORGANIZATION_SELF_PRACTICE_LIMIT.organizationSelfPracticeLimit,
        shareLeftThisMonth: ORGANIZATION_SELF_PRACTICE_LIMIT.organizationSelfPracticeLimit,
      }),
      selfPracticeLinkAccessLimit: this.attr(HOME_PRACTICE_OPTIONS.LINK_ACCESS_LIMIT),
      privateSiteAccess: this.attr(null),
      roles: this.attr([
        {
          role_id: this.attr(null),
          id: this.belongsTo(Role, 'role_id'),
          name: this.attr(null),
        },
      ]),
      preferences: this.attr({
        allowsTrackingId: this.attr(true),
        printsLimit: ORGANIZATION_PRINTS_LIMIT.orgPrintLimit,
        printsLeftThisMonth: ORGANIZATION_PRINTS_LIMIT.orgPrintsLeftThisMonth,
        autoGeneratedClientID: this.attr(null),
        hasClientName: this.attr(null),
        allowCustomMessageToSelfPractice: this.attr(true),
      }),
      totalClientLicenses: this.attr(null),
      totalUserLicenses: this.attr(null),
      defaultLanguageCode: this.attr(null),
      comments: this.attr(null),
      purchaseComments: this.attr(null),
      externalPaymentPlan: this.attr(false),
      autoLogout: this.attr(false),
      autoLogoutOnBrowserClose: this.attr(false),
      autoLogoutTimeout: this.attr(0),
      autoLogoutUrl: this.attr(null),
      websiteAccess: this.attr([]),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    };
  }
}
