export enum PHONETICS_WORD_TYPE_REFERENCE {
  WORD = 'Phonetics-Words',
  PHRASE = 'phonetics-phrases',
  SENTENCE = 'phonetics-sentences',
}

export enum ACTIVITY_GENERATION_TYPE_REFERENCE {
  ACTIVITY = 'Activity',
  USER_DEFINED_ACTIVITY = 'user-defined-activity',
}
