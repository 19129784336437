import mongoose, { Document, Schema } from 'mongoose';
export interface Strips {
  name: string;
  items: any;
}

export interface MongooseStrips extends Strips, Document {}

const StripsSchema = new Schema(
  {
    name: { type: String, unique: true, index: true },
    groups: [
      {
        name: { type: Object },
        // languageCode: { type: [String], enum: ACTIVITY_ENUM.languageCode },
        languageCode: {
          type: [Schema.Types.ObjectId],
          default: [],
          ref: 'Website',
        },
        items: [{ type: mongoose.Schema.Types.ObjectId, ref: 'Activity' }],
      },
    ],
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const Strip = mongoose.model<MongooseStrips>(
  'Strip',
  StripsSchema
) as any;
