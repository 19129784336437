import mongoose, { Document, Schema } from 'mongoose';

export interface UserRecommendationSelection {
  website: string;
  isPublic: Boolean;
  customizedInterface: Boolean;
  inheritInterfaceFrom: Schema.Types.ObjectId;
  phonologyInheritance: Schema.Types.ObjectId;
  language: Schema.Types.ObjectId;
}

export interface MongooseUserRecommendationSelection
  extends UserRecommendationSelection,
    Document {}

const UserRecommendationSelectionSchema = new Schema(
  {
    beforeRecommendationActivity: {
      type: Schema.Types.ObjectId,
      default: null,
    },
    selectedRecommendation: {
      selectedStripIndex: { type: Number, default: null },
      selectedActivityIndex: { type: Number, default: null },
      selectedRecommendationActivity: {
        type: Schema.Types.ObjectId,
        default: null,
      },
    },
    stripInfo: { type: Array, default: [] },
    activityFeedBack: { type: Schema.Types.ObjectId, default: null },
    clientActivity: { type: Schema.Types.ObjectId, default: null },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

export const UserRecommendationSelection = mongoose.model<MongooseUserRecommendationSelection>(
  'User-Recommendation-Selection',
  UserRecommendationSelectionSchema
) as any;
