import mongoose, { Document, Schema } from 'mongoose';
import { SubCategory } from './subcategory';

export interface Category {
  name: object;
}

export interface MongooseCategory extends Category, Document {}

const CategorySchema = new Schema(
  {
    name: { type: Object, unique: true, index: true },
    weight: { type: mongoose.Schema.Types.Number },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

CategorySchema.post('remove', async (doc) => {
  // TODO: remove any and check this.
  await SubCategory.remove({
    category: new mongoose.mongo.ObjectId(doc._id) as any,
  });
});

export const Category = mongoose.model<MongooseCategory>(
  'Category',
  CategorySchema
) as any;
