import axios from 'axios';

export const getAllDomains = () => axios({ url: `/api/v1/activity-domain` });
export const getAllDomainsTypes = () => axios({ url: `/api/v1/activity-type` });
export const getAllCategory = () => axios({ url: `/api/v1/category` });
export const getAllSubcategory = () => axios({ url: `/api/v1/subcategory` });
export const uploadMedia = (data) =>
  axios({
    url: `/api/auth/upload-media`,
    method: 'post',
    data,
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
  });

export const cloneMedia = (data) =>
  axios({
    url: `/api/auth/clone-media`,
    method: 'post',
    data,
    onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
  });

export const generateAudioFiles = (data) =>
  axios({
    url: `/api/auth/generate-audio-files`,
    method: 'post',
    data,
  });

export const generateAudioFromText = (data) =>
  axios({
    url: `/api/auth/generate-audio-from-text`,
    method: 'post',
    data,
  });

export const invalidateCDNPath = (data) =>
  axios({
    url: `/api/auth/invalidate-cdn-paths`,
    method: 'post',
    data,
  });

export const deleteActivityMediaFolder = (data) => axios({ url: `/api/auth/delete-activity-media`, method: 'post', data });

// export const getActivityShallow = (id) => axios({ url: `/api/v1/activity/${id}?select=name,metadata.coverFilename` });
// url: `/api/v1/activity/${id}?populate=[{"path":"type", "populate": [{"path": "domain"},{"path":"sourceActivityType"}] }, {"path":"published", "populate" : [{"path" : "language"}]}]`,

export const getActivityShallow = (id) =>
  axios({
    url: `/api/v1/activity/${id}?populate=[{"path":"type", "populate": [{"path": "domain"},{"path":"sourceActivityType"}] },{"path" : "ownedBy"}, {"path" : "updatedBy"}, {"path" : "createdBy","populate": [{"path": "organization"}]}]`,
  });

// export const getArticulationShallow = () => axios({ url: `/api/v1/articulation?select=name,category,title` });
// export const getArticulationShallow = (id) => axios({ url: `/api/v1/articulation/${id}` });
export const getArticulationShallow = (id) =>
  axios({
    url: `/api/v1/articulation/${id}?populate=[{"path":"metadata.syllable.vowels", "options": { "retainNullValues": true }, "select" : {"display" : true,"letter" : true,"name" : true, "topCategory" : true, "isHidden": true}}]`,
  });

export const getVowels = (language) => {
  const query = { topCategory: 'articulation-consonants', languageCode: language };
  const select = { display: 1, name: 1 };

  // axios({ url: `/api/v1/articulation?query={"topCategory":"articulation-consonants", languageCode='en-us'}&select={name: "1"}` });
  return axios({ url: `/api/v1/articulation?query=${JSON.stringify(query)}&select=${JSON.stringify(select)}` });
};

export const getConsonantsVowels = (language) => {
  const query = { topCategory: 'articulation-vowels', languageCode: language };
  const select = { display: 1, name: 1 };

  return axios({ url: `/api/v1/articulation?query=${JSON.stringify(query)}&select=${JSON.stringify(select)}` });
};

export const getPhoneticsVowels = (language) => {
  const query = { type: 'vowel', language: language };
  return axios({ url: `/api/v1/phonetics-sounds?query=${JSON.stringify(query)}` });
};

export const getPhoneticsConsonants = (language) => {
  const query = { type: 'consonant', language: language };

  return axios({ url: `/api/v1/phonetics-sounds?query=${JSON.stringify(query)}` });
};

export const getStripsShallow = (id) => axios({ url: `/api/v1/strip/${id}` });
export const getOrganizationShallow = (id) => axios({ url: `/api/v1/organization/${id}` });
export const getSurveyShallow = (id) => axios({ url: `/api/v1/survey/${id}` });
export const getCategoryShallow = (id) => axios({ url: `/api/v1/category/${id}` });

export const getLanguage = () => axios({ url: `/api/v1/languages` });

export const getUserShallow = (id) => axios({ url: `/api/v1/user/${id}?populate=[{"path":"type", "populate": [{"path": "domain"}]}]` });

export const getUserQueryShallow = (query) => axios({ url: `/api/v1/user?query={${query}}` });

export const getStripsWithActivity = (stripToLoad) => axios(`/api/v1/strip/${stripToLoad}/activity`);

export const login = (data) => axios({ url: `/api/auth/login`, method: 'post', data });
export const register = (data) => axios({ url: `/api/auth/register`, method: 'post', data });
export const requestPasswordReset = (data) => axios({ url: `/api/auth/request-password-reset`, method: 'post', data });
export const reverify = () => axios({ url: `/api/auth/reverify`, method: 'post' });

export const getAllBoardsWithActivity = (id) => axios(`/api/v1/board/${id}/activity`);
export const createBoard = (data) => axios({ url: `/api/v1/board`, method: 'post', data });

export const renameBoard = (id, data) => axios({ url: `/api/v1/board/${id}`, method: 'patch', data });
export const deleteBoard = (id) => axios({ url: `/api/v1/board/${id}`, method: 'delete' });
export const getAllBoards = () => axios(`/api/v1/board`);

export const inviteUser = (data) => axios({ url: `/api/auth/invite`, method: 'post', data });
// export const inviteEmail = (data) => axios({ url: `/api/auth/invite-email`, method: 'post', data });

export const findActivityOne = (id) => axios(`/api/v1/activity/${id}?forceGetLatest=${new Date().toISOString()}`);
export const findArticulationOne = (id) =>
  axios(
    `/api/v1/articulation/${id}?populate=[{"path":"metadata.syllable.vowels", "options": { "retainNullValues": true }, "select" : {"display" : true,"letter" : true,"name" : true, "topCategory" : true}}]`
  );

export const findCouponOne = (id) => axios(`/api/v1/coupon?query={"code": "${id}" }`);
export const findPlanOne = (plan) => axios(`/api/v1/plan/${plan}`);

export const deleteAccount = (plan) => axios({ url: `/api/auth/delete-account`, method: 'post', data: plan });
export const cancelInvite = (data) => axios({ url: `/api/auth/uninvite`, method: 'post', data });
// export const currentUser = () => axios({ url: `/api/auth/me` });
export const updateUserName = (data) => axios({ url: `/api/auth/user/name`, method: 'post', data });

// export const updateUserProfile = (data) => axios({ url: `/api/auth/profile`, method: 'put', data });

export const searchActivities = (searchQuery) => axios({ url: `/api/v1/activity`, params: searchQuery });
export const searchUsers = (searchString) =>
  axios({ url: `/api/v1/user?select=email&query={"email": { "$regex": ".*${searchString}.*"}}` });

export const searchOrganizations = (searchString) =>
  axios({ url: `/api/v1/organization?select=name&query={"name": { "$regex": ".*${searchString}.*"}}` });

export const getPlans = () => axios(`/api/v1/plan`);

export const unpinFromBoard = (boardId, activityId) =>
  axios({
    url: `/api/v1/board/${boardId}/items`,
    method: 'patch',
    data: { item: activityId },
  });

export const renameUser = (username) => axios({ url: `/api/auth/user/name`, method: 'post', data: { username } });

export const updateProfile = (profile) => axios({ url: `/api/auth/profile`, method: 'put', data: { profile } });

export const changePassword = (form, token) =>
  axios({ url: `/api/auth/password`, method: 'post', data: form, headers: { Authorization: `bearer ${token}` } }); //, ignoreUnauthorization: true });

export const addOrRemoveActivityFromBoard = (boardId, activityId) =>
  axios({ url: `/api/v1/board/${boardId}/items`, method: 'patch', data: { item: activityId } });

export const purchase = ({ couponCode, plan, licenses }) =>
  axios({ url: `/checkout/coupon`, method: 'post', data: { couponCode, plan, licenses } });

export const filterStats = (typeId) => axios({ url: `/api/v1/filter/${typeId}` });

/************ */

/** admin */

export const getActivities = () => axios({ url: `/api/v1/activity` });

export const createOneActivity = (data) => axios({ url: `/api/v1/activity`, method: 'post', data });

export const updateActivity = (activity) =>
  axios({
    url: `/api/v1/activity/${activity._id}?populate=[{"path":"attachedRoles","select":"name"},{"path" : "ownedBy"}, {"path" : "updatedBy"}, {"path" : "createdBy","populate": [{"path": "organization"}]}, {"path":"type", "populate": [{"path": "domain"}, {"path":"sourceActivityType"}]}]`,
    method: 'put',
    data: activity,
  });

export const deleteActivity = (id) => axios({ url: `/api/v1/activity/${id}`, method: 'delete' });

export const getArticulations = () => axios({ url: `/api/v1/articulation` });

export const getStrips = () => axios({ url: `/api/v1/strip` });

export const saveAllStrips = (collections) => axios({ url: `/api/v1/strip`, method: 'put', data: collections });

export const saveCategories = (id, data) => axios({ url: `/api/v1/category/${id}`, method: 'patch', data });
export const saveSubcategories = (id, data) => axios({ url: `/api/v1/subcategory/${id}`, method: 'patch', data });

export const createCategory = (data) => axios({ url: `/api/v1/category`, method: 'post', data });
export const removeCategory = (id) => axios({ url: `/api/v1/category/${id}`, method: 'delete' });

export const createSubcategory = (data) => axios({ url: `/api/v1/subcategory`, method: 'post', data });
export const removeSubcategory = (id) => axios({ url: `/api/v1/subcategory/${id}`, method: 'delete' });
export const updateSubcategory = (id, subCategory) => axios({ url: `/api/v1/subcategory/${id}`, method: 'patch', data: subCategory });

export const updateCategory = (id, category) => axios({ url: `/api/v1/category/${id}`, method: 'patch', data: category });

export const updateStrip = (id, strip) => axios({ url: `/api/v1/strip/${id}`, method: 'patch', data: strip });
export const removeStrip = (id) => axios({ url: `/api/v1/strip/${id}`, method: 'delete' });

export const updateArticulation = (articulation) =>
  axios({
    url: `/api/v1/articulation/${articulation._id}?populate=[{"path":"attachedRoles"},{"path":"metadata.syllable.vowels", "options": { "retainNullValues": true }, "select" : {"display" : true,"letter" : true,"name" : true, "topCategory" : true}}]`,
    method: 'put',
    data: articulation,
  });

export const getUsers = () => axios({ url: `/api/v1/user` });

export const loadEntity = (entity, articulationLanguageCodes = null, selectFields = null, queryString = null, sortings = null) => {
  const query = articulationLanguageCodes ? `query={"languageCode": {"$in":${articulationLanguageCodes}}}&` : '';
  const filterQuery = queryString?.length > 0 ? `&query={"$and":${JSON.stringify(queryString)}}` : '';
  let select = '';
  if (selectFields) {
    select += `&select=${selectFields}`;
  }
  // return axios(
  //   `/api/v1/${entity.toLowerCase()}?${query}populate=[{"path":"organization","select":"name"},{"path":"adminUsersIds","select":"email"},{"path":"attachedRoles","select":"name"}]${select}`
  // );
  return axios(`/api/v1/${entity.toLowerCase()}?${query}${select}${filterQuery}${sortings ? `&sort=-${sortings}` : ''}`);
};

export const updateUser = (id, user) => axios({ url: `/api/v1/user/${id}`, method: 'patch', data: user });
export const removeUser = (id) => axios({ url: `/api/v1/user/${id}`, method: 'delete' });

export const updateSurvey = (id, survey) => axios({ url: `/api/v1/survey/${id}`, method: 'patch', data: survey });
export const removeSurvey = (id) => axios({ url: `/api/v1/survey/${id}`, method: 'delete' });

export const updateOrganization = (id, organization) =>
  axios({ url: `/api/v1/organization/${id}?populate={"path":"adminUsersIds","select":"email"}`, method: 'patch', data: organization });
export const removeOrganization = (id) => axios({ url: `/api/v1/organization/${id}`, method: 'delete' });

export const updateUserInState = () => axios({ url: `/api/v1/user/user-by-token` });
export const createActivityBackup = (data) => axios({ url: `/api/v1/activity-backup`, method: 'post', data });

export const getAllOrganizations = () => axios({ url: `/api/v1/organization?select={"name":1, "websiteAccess" : 1}`, method: 'get' });

export const getPhoneticsStudioData = (data) => axios({ url: `/api/v1/phonetics-studio`, method: 'post', data });

export const getPhoneticsStudioStructureData = (languageId) => {
  const query = { languages: { $in: [languageId] } };

  return axios({ url: `/api/v1/phonetics-structure?query=${JSON.stringify(query)}` });
};

export const getPhoneticsUniqueTags = () => {
  return axios({ url: `/api/v1/unique-phonetics-tag` });
};

export const createPhoneticsStudioWord = (data) => axios({ url: `/api/v1/phonetics-word`, method: 'post', data });

export const updatePhoneticsStudioWord = (data) => axios({ url: `/api/v1/phonetics-word/${data._id}`, method: 'put', data });

export const getSinglePhoneticsStudioWordData = (id) =>
  axios({
    url: `/api/v1/phonetics-words/${id}?populate=[{"path":"ownedBy","select":{
  "name": 1 }},{"path":"createdBy","select":{ "name": 1 }},{"path":"updatedBy","select":{ "name": 1 }}]`,
    method: 'get',
  });

export const createPhoneticsStudioWordBackup = (data) => axios({ url: `/api/v1/phonetics-words-logs`, method: 'post', data });

export const deletePhoneticsStudioWord = (id) => axios({ url: `/api/v1/phonetics-word/${id}`, method: 'delete' });

export const updatedByUsersList = () =>
  axios({
    url: `/api/v1/update-by-users-list`,
    method: 'get',
  });

export const createPhoneticsStudioPhrase = (data) => axios({ url: `/api/phonetics-phrase`, method: 'post', data });

export const updatePhoneticsStudioPhrase = (data) => axios({ url: `/api/phonetics-phrase/${data._id}`, method: 'patch', data });

export const getSinglePhoneticsPhraseData = (id) => axios({ url: `/api/phonetics-phrase/${id}`, method: 'get' });

export const deletePhoneticsStudioPhrase = (id) => axios({ url: `/api/phonetics-phrase/${id}`, method: 'delete' });

export const createPhoneticsStudioSentence = (data) => axios({ url: `/api/phonetics-sentence`, method: 'post', data });

export const updatePhoneticsStudioSentence = (data) => axios({ url: `/api/phonetics-sentence/${data._id}`, method: 'patch', data });

export const getSinglePhoneticsSentenceData = (id) => axios({ url: `/api/phonetics-sentence/${id}`, method: 'get' });

export const deletePhoneticsStudioSentence = (id) => axios({ url: `/api/phonetics-sentence/${id}`, method: 'delete' });
