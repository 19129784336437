import moment from 'moment';
import { emailVerifyInOrg } from '../../mongodb/src';

export enum Roles {
  ADMIN = 'admin',
  ADMIN_TEXT_EDITOR = 'adminTextEditor',
  COGNISHINE_CONTRIBUTOR = 'CognishineContributor',
  SUBSCIBER = 'subscriber',
  CLALIT = 'clalit',
  ORG_CONTRIBUTOR = 'OrgContributor',
  TMP_CONTRIBUTOR = 'tmpContributor',
}

export const organizationRoles = [Roles.SUBSCIBER];

export const webApplicationRoles = [Roles.SUBSCIBER, Roles.ADMIN];

// export const verify = async (role) => {
//   if (!role || !role.type) throw new Error('ROLE_INVALID');
//   if (role.expires != null) {
//     const days = moment.duration(moment().utc().diff(role.expires)).asDays();
//     const expired = days > 0;
//     if (expired) throw new Error('ROLE_EXPIRED');
//   }

//   return Object.values(Roles).includes(role.type);
// };

// export const verifySync = (role) => {
//   if (!role || !role.type) return false;

//   if (role.expires != null) {
//     const days = moment.duration(moment().utc().diff(role.expires)).asDays();
//     const expired = days > 0;
//     if (expired) return false;
//   }

//   return Object.values(Roles).includes(role.type.toLowerCase());
// };

// export const hasRole = async (role, expectedRole: Roles) => {
//   await verify(role);

//   return role.type === expectedRole;
// };

// export const inRoles = async (role, expectedRoles, allRoles) => {
//   await verify(role);

//   console.log(expectedRoles);
//   // const allRoles = Object.values(Roles);
//   return expectedRoles
//     .filter((e) =>
//       allRoles
//         .filter((role) => role.enabled)
//         .map((role) => role.name)
//         .includes(e)
//     )
//     .includes(role.type.toLowerCase());
// };

export const verifyRole = (role) => {
  if (!role || !role.id) return false;
  if (role.expirationDate != null) {
    const days = moment
      .duration(moment().utc().diff(role.expirationDate))
      .asDays();
    const expired = days > 0;
    if (expired) return false;
  }
  return true;
};

export const hasValidRoles = (userRoles, expectedRoles: Roles[], allRoles) => {
  const expectedRolesObjects = allRoles.filter((role) =>
    expectedRoles.includes(role.name)
  );
  const userRolesIds = userRoles.map((role) => role.id);

  for (const role of expectedRolesObjects) {
    if (userRolesIds.includes(role['_id'])) {
      return verifyRole(
        userRoles.find(
          (userRole) => role['_id'].toString() === userRole.id.toString()
        )
      );
    }
  }
  return false;
};

export const combineUserAndOrgRoles = (user) => {
  const userRoles = user?.roles || [];
  const userOrgRoles =
    (user?.organizationStatus === emailVerifyInOrg.VERIFIED
      ? user?.organization?.roles
      : []) || [];

  const userRolesNotInOrg = userRoles?.filter(
    (userRole) =>
      !userOrgRoles.some(
        (userOrgRole) =>
          userRole?.id?.toString() === userOrgRole?.id?.toString()
      )
  );

  return userRolesNotInOrg.concat(userOrgRoles);
};

export function getUserAndOrgRolesIds(user) {
  const combineRoleIds = [
    ...new Set([
      ...(user?.value?.roles?.map((role) => role.id) || []),
      ...(user?.value?.organization?.roles?.map((role) => role.id) || []),
    ]),
  ];
  return combineRoleIds;
}

export function hasRoleInUserOrOrg(user, roleId) {
  const userOrOrgRolesIds = getUserAndOrgRolesIds(user);
  return (
    userOrOrgRolesIds &&
    userOrOrgRolesIds.length > 0 &&
    userOrOrgRolesIds.includes(roleId)
  );
}
